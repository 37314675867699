import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { LoggedInAppUser } from '../../models/loggedInAppUser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUser } from '../../models/appUser';
import { SecretQuestion } from '../../models/secretQuestion';
import { HttpEventType } from '@angular/common/http';
import { NewAppUser } from '../../models/newAppUser';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';
import { map } from 'rxjs/operators';
import { AppUserService } from 'src/app/dashboard/services/app-user.service';
import { AppSettings } from 'src/app/shared/models/appsettings';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';

@Component({
    selector: 'user-registration',
    templateUrl: 'user-registration.component.html'
})

export class UserRegistrationComponent implements OnInit {
    @ViewChild('frmLogin') frm: any;
    user: AppUser;
    file: any;
    userId: number=0;
    secretQuestions: SecretQuestion[];
    public progress: number;
     public message: string;
    imageId: number=0
    private appSettings: AppSettings;
    ChangeImage: boolean = false;
    constructor(private userService: UserService, private router: Router, private zone:NgZone, 
        private notificationService: AppNotificationService, 
        private route: ActivatedRoute, 
        private appUserService: AppUserService,
        private appSettingsService: AppSettingsService) { 

    }

    ngOnInit() {
        this.appSettingsService.getSettings().subscribe(data => {
            //console.log(data);
            this.appSettings = data;
        });
        this.route.params.pipe(map(x => x.id)).subscribe(id => 
            {
                if(id){
                    this.userId = +id;
                    this.appUserService.getAppUserDetail(+id).subscribe(data => {
                        //console.log(data);
                        this.user = {
                            id: data.id,
                            avatar_url: data.avatar_url,
                            bio: data.bio,
                            blog: '',
                            fname: data.fname,
                            lname: data.lname,
                            email: data.email,
                            html_url: '',
                            twitter: data.twitter,
                            company: data.company,
                            password: data.password,
                            gitAccount: data.gitAccount,
                            linkedInAccount: data.linkedInAccount,
                            secQ1Id:data.secretQuestions[0].secretQuestionId,
                            seQ1Ans: data.secretQuestions[0].secretQuestionAnswer,
                            secQ2Id: data.secretQuestions[1].secretQuestionId,
                            seQ2Ans: data.secretQuestions[1].secretQuestionAnswer,
                            profilePicId: data.profilePicId
                        };
                        this.imageId = data.profilePicId;
                        this.user.avatar_url = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.documents + '/' + data.profilePicId;
                    }, (error) => {
                        this.notificationService.addNotification(`${error}`);
                        let userId = this.userService.getLocalStorage("AuthToken").userId;
                        this.router.navigate(['MyProfile', userId]);
                    });
                }
                else{
                    this.user = {
                        id: 0,
                        avatar_url: '',
                        bio: '',
                        blog: '',
                        fname: '',
                        lname: '',
                        email: '',
                        html_url: '',
                        twitter: '',
                        company: '',
                        password: '',
                        gitAccount: '',
                        linkedInAccount: '',
                        secQ1Id: 0,
                        seQ1Ans: '',
                        secQ2Id: 0,
                        seQ2Ans: '',
                        profilePicId: 0
                    };
                }
            }
        );
        

        this.userService.getSecretQuestions().subscribe(
            questions => this.secretQuestions = questions);
    }
    
    // uploadFile(event) {
    //     this.file = event.target.files[0];  
    // }

    CloseImage(){
        this.ChangeImage = true;
        this.imageId=0;
        return false;
    }
    Submit() { 
        let user: AppUser = this.frm.value as AppUser;     
        let newUser: NewAppUser = {
            profilePicId: 0,
            bio: '',
            //blog: '',
            fname: '',
            lname: '',
            email: '',    
            //html_url: '',
            twitter: '',
            company: '',
            password: '',
            gitAccount: '',
            linkedInAccount: '',
            secretQuestions: [],
            gitLogIn: false,
            avatar_url: '',
            id: 0
        };
        //newUser.avatar_url = user.avatar_url;
        console.log(user);
        newUser.bio = user.bio;
        //newUser.blog = user.blog;
        newUser.fname = user.fname;
        newUser.lname = user.lname;
        newUser.email = user.email;   
        //newUser.html_url = user.html_url;
        newUser.twitter = user.twitter;
        newUser.company = user.company;
        newUser.password = user.password;
        newUser.gitAccount = user.gitAccount;
        newUser.linkedInAccount = user.linkedInAccount;
        if(this.imageId == 0)
            this.imageId = 1;
        newUser.profilePicId = this.imageId;
        newUser.secretQuestions = [];
        newUser.secretQuestions.push(
            {
                secretQuestionId: +user.secQ1Id, 
                secretQuestionAnswer: user.seQ1Ans
            });
        newUser.secretQuestions.push(
            {
                secretQuestionId: +user.secQ2Id, 
                secretQuestionAnswer: user.seQ2Ans
            });
        newUser.id = this.userId;
        //console.log('XYZ Testing');

        //console.log(newUser);

        if(newUser.secretQuestions[0].secretQuestionId == newUser.secretQuestions[1].secretQuestionId)
        {
            this.notificationService.addNotification('Secret Questions should not be same.');
        }
        else
        {
            let updateNewUser: boolean = newUser.id != 0;
            this.userService.saveUser(newUser).subscribe(data => {
                this.router.navigate(['Home']);
                if(updateNewUser) {
                    this.notificationService.addNotification('User updated successfully.');
                }
                else {
                    this.notificationService.addNotification('User Registration completed successfully.');
                }
            });
        }

        
        /*
        this.userService.saveUser(user).then(() =>         
            {
                this.userService.upload(this.file, user.email).then(
                    (id) => { this.zone.run(() => this.router.navigate(['dashboard', id])); this.Clear(); },
                    () => console.log('error occured'));
            }
        );
        */                
    }

    Clear(){        
        this.frm.reset();
    }

    uploadFile = (files) => {
        
        if (files.length === 0) {
          return;
        }
        let fileToUpload = <File>files[0];
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        this.userService.uploadFormData(formData).subscribe(event => {
            //console.log(event as HttpEventType.Response);
            if (event.type === HttpEventType.UploadProgress)
              this.progress = Math.round(100 * event.loaded / event.total);
            else if (event.type === HttpEventType.Response) {
              this.message = 'Upload success.';
              //this.onUploadFinished.emit(event.body);

              console.log(event.body);
              this.imageId = +event.body;
            }
          });
      }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';
import { resetPassword } from '../../models/resetPassword';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html'    
})
export class ResetPasswordComponent implements OnInit {
    @ViewChild('frmResetPassword') frm : any;
    resetPassword: resetPassword;
    detailFound: boolean = false;
    constructor(private userService: UserService, private notificationService: AppNotificationService, private router: Router) { }

    ngOnInit(): void { 
        this.resetPassword = {
            email: '',
            secretQuestion1: '',
            secretQuestion2: '',
            answer1: '',
            answer2: '',
            password: ''
        };
    }

    Submit(){
        let resetPwd: resetPassword = this.frm.value as resetPassword;     
        console.log(this.resetPassword.answer1);
        console.log(this.resetPassword.secretQuestion1);

        this.userService.ResetPassword(this.resetPassword).subscribe(data => 
            {
                console.log(data);
                this.notificationService.addNotification('Password reset successfully.');
                this.router.navigate(['Home']);
                //this.Clear();
            }, 
            (error) => {
                this.notificationService.addNotification(`Password reset Failed. ${error}`);
                
            }
        );
       
    }

    FetchDetail(email){
        this.userService.FetchSecretQuestions(email).subscribe(data => {
            this.detailFound = true;
            this.resetPassword = data;
        });
    }

    Clear(){     
        this.frm.reset();
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/shared/models/appsettings';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { dailyCodeSample } from '../models/dailyCodeSample';

@Injectable({
    providedIn: 'root'
})
export class DailyCodeSampleService {
    private appSettings: AppSettings;
    constructor(private http: HttpClient, private appSettingsService: AppSettingsService){
        console.log('DailyCodeService constuctor');
        this.appSettingsService.getSettings().subscribe(data => {          
          this.appSettings = data;});
    }

    getAllDailyCodeSamples(): Observable<dailyCodeSample[]>
    {
        let allDailyCodeSamplesUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.dailyCodeSamples;         
        return this.http.get<dailyCodeSample[]>(allDailyCodeSamplesUrl);
    }
}
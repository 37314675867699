import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { payment } from '../models/payment';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppSettings } from 'src/app/shared/models/appsettings';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { apiPayment } from '../models/apipayment';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    private appSettings: AppSettings;
    constructor(private http: HttpClient, private appSettingsService: AppSettingsService){
      console.log('CoursesService constuctor');
      this.appSettingsService.getSettings().subscribe(data => {
        //console.log(data);
        this.appSettings = data;});
    }


    getPaymentToMakeDetail(userId: number, courseId: number, sectionId: number, msg: string): Observable<payment>{
        if(msg)
            return of(null);
        var paymentToMakeUrl = `${this.appSettings.techCodeLibAPIBaseUrl}${this.appSettings.getAppUserPaymentToMake}?userId=${userId}&courseId=${courseId}&sectionId=${sectionId}`;
        console.log(paymentToMakeUrl);
        return this.http.get<payment>(paymentToMakeUrl).pipe(catchError(this.handleError));
    }

    makeAPayment(apiPayment: apiPayment): Observable<any>{
        //let apiPaymentUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.makeApiPayment;
        return this.http.post<any>('https://test.cashfree.com/billpay/checkout/post/submit', apiPayment).pipe(catchError(this.handleError));
    }

    getSignature(apiPayment: apiPayment): Observable<any>{
        let apiPaymentUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.getSignature;
        return this.http.post<any>(apiPaymentUrl, apiPayment).pipe(catchError(this.handleError));
    }
    private handleError(error: HttpErrorResponse) {
        console.log(error.error);
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        let errorMsg =  (error.error != null)? error.error.msg: '';
        if(!errorMsg)
            errorMsg = 'Something bad happened; please try again later.'
        return throwError(errorMsg);
    }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/security/services/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenInterceptorService implements HttpInterceptor {
    constructor(private userService: UserService){}
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
      if(request.url.indexOf("api.github.com") == -1)
      {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.userService.getLocalStorage("AuthToken").token}`
          }
        });
    
        
      }
      return next.handle(request);
    }
}
<div class="w3-row">
    <div class="w3-col">        
        <p><b>All Courses:</b> We have below courses on our portal. Some of the courses are ready and we will keep adding courses everyweek.</p>
        <span style="color: red;">Change the zoom level according to your screen size.</span>
    </div>
</div>
<div class="w3-row">       
    <div class="w3-col" style="overflow-y: auto;overflow-x: auto;">
        <iframe src="https://docs.google.com/spreadsheets/d/13tH2RBcvvaQ9KOAGePfDtz71BsHLx7jIpZ1_tC9DM3A/edit?usp=sharing" 
        style="width:100%; height:610px; border:2px solid grey;"></iframe>
    </div>
</div>
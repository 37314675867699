import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { AppRoutingModule } from './routing/app-routing.module';
import { HomeComponent } from './home/components/home/home.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { LoginComponent } from './security/components/login/login.component';
import { FormsModule } from '@angular/forms';
import { UserRegistrationComponent } from './security/components/user-registration/user-registration.component';
import { AngularFireStorageModule} from "@angular/fire/storage";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AuthTokenInterceptorService } from './shared/interceptors/auth-token-interceptor.service';
import { CoursesModule } from './courses/courses.module';
import { SendMessageComponent } from './messages/send-message.component';
import { CodeReposModule } from './code-repos/code-repos.module';
import { LandingPageComponent } from './landing-page/components/landing-page.component';
import { FaqComponent } from './FAQ/components/faq.component';
import { AboutComponent } from './about/components/about.component';
import { NotificationComponent } from './shared/components/notification.component';
import { AppEventComponent } from './user-notifications/components/event-info.component';
import { CareerPathModule } from './career-path/career-path.module';
import { ResetPasswordComponent } from './security/components/reset-password/reset-password.component';
import { CourseCertificatesModule } from './certificates/course-certificates.module';
import { UpdatesComponent } from './updates/updates.component';
import { PaymentModule } from './payments/payment.module';
import { BlogsModule } from './blogs/blogs.module';
import { DailyCodeSamplesModule } from './daily-code-samples/daily-code-sample.module';


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    LoginComponent,
    UserRegistrationComponent,
    SendMessageComponent,
    LandingPageComponent,
    FaqComponent,
    AboutComponent,
    NotificationComponent,
    AppEventComponent,
    ResetPasswordComponent,
    UpdatesComponent       
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    DashboardModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, FormsModule, AngularFireStorageModule,
    CoursesModule,
    CodeReposModule,
    CareerPathModule,
    CourseCertificatesModule,
    PaymentModule,
    BlogsModule,
    DailyCodeSamplesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { socialMediaPost } from '../social-media/models/socialMediaPost';
import { SocialMediaService } from '../social-media/services/social-media.services';

@Component({
    selector: 'app-updates',
    templateUrl: './updates.component.html',
    
})
export class UpdatesComponent implements OnInit, AfterViewInit {
    socialMediaPosts: socialMediaPost[];
    constructor(private socialMediaService: SocialMediaService, private sanitizer: DomSanitizer) { }

    ngOnInit(): void { 
        this.socialMediaService.getAllSocialMediaPosts().subscribe(data => {
            this.socialMediaPosts = data;
            console.log('done');
        });
    }

    ngAfterViewInit(): void {
        (<any>window).twttr.widgets.load();
    }

    getUrl(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}

<div class="container">
  <div class="w3-row">
    <div class="w3-col w3-row-padding w3-twothird w3-hide-small">
      <div class="w3-card">
        <header class="w3-theme-d2">
          <div class="w3-container">
            <h2>Welcome users!</h2>
          </div>
        </header>
        <div class="w3-container">
          <div class="w3-row">
            <div class="w3-col">
              <p>The <b>TechCodeLib</b> is an online library for video courses, 
                code samples, tech tutorials etc. for software technologies. It provides an easy and practical way of learning.
                <br>We  can help you learn and improve your coding skills. 
                If you are just beginner, have little programming knowledge, Junior, or Senior Developer, 
                Tech-Code-Lib will train you on some of the top-notch technologies.

                <br>
                Sign In with your credentials or sign in with GitHub.
                </p>

              <p>Having trouble logging in or don’t know your access credentials? Please reach out to <u style="color: orangered;">support@techcodelib.com</u> for your user login and password.</p>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w3-col w3-row-padding w3-third" *ngIf="!loggedIn">
      <app-login></app-login>
    </div>
  </div>
</div>
<h2>Tech Code Lib Blogs</h2>
<table class="w3-table">
    <thead>
        <tr class="w3-light-grey">
            <th>Title</th>
            <th>Detail</th>
            <th>Subject</th>
            <th>Tags</th>
            <th>View</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="!blogs">
            <td colspan="3" style="color: red;">No blog found.</td>
        </tr>
        <tr *ngFor="let blog of blogs">
            <td>{{ blog.title }}</td>
            <td>{{ blog.detail }}</td>
            <td>{{ blog.subject }}</td>
            <td>{{ blog.tags }}</td>
            <td>
                <a [href]="blog.webLink" target="_blank"
                    class="w3-bar-item w3-button w3-padding-small w3-hover-white" title="View">
                    <i class="fa fa-external-link-square" style="font-size:25px"></i>
                </a>
            </td>
        </tr>
        <tr>
            <td colspan="3">&nbsp;</td>
        </tr>
    </tbody>
</table>
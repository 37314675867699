import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { careerPath } from 'src/app/career-path/models/careerPath';
import { CareerPathService } from 'src/app/career-path/services/career-path.service';
import { UserService } from 'src/app/security/services/user.service';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    
})
export class LandingPageComponent implements OnInit {
 loggedIn: boolean = true;
  careerPaths: careerPath[];
  constructor(private userService: UserService, private router: Router, private careerPathService: CareerPathService) { }

  ngOnInit(): void {
    this.careerPathService.getAllCareerPaths().subscribe(data => this.careerPaths = data);
    setTimeout(() => {
      this.userService.checkUserLoggedInOrNot().subscribe(val => {
        this.loggedIn = (val != null && val.token != "");
        console.log(val);
        if (val && val.token != "") {
          this.userService.isLoggedIn = true;
          //this.userService.isAdminSub.next(val.IsAdmin)
          if (!this.userService.redirectUrl) {
            this.userService.redirectUrl = '';
          }
          this.router.navigate([this.userService.redirectUrl]);
        }
      });
    }, 100)
  }

    navigateToNewUser(){
        this.router.navigate(['/UserReg']);
    }

    navigateToSignIn(){
        this.router.navigate(['/Home']);
    }
}

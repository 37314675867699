<h3>FAQs</h3>
<div class="w3-row">   
    <div class="w3-col">
        <p style="color:brown">
            <b>Q: </b>
            I don't see many courses on the portal when can I see other new courses?
        </p>
        <p style="color:navy">
            <b>Ans:</b>
            We are working on creating new courses everyweek. Within few months you will see lot 
            of courses added on the portal. However, we will provide enough content/video sessions/code samples/live training sessions everyweek.
        </p>
    </div> 
    <div class="w3-col">
        <p style="color:brown">
            <b>Q: </b>
            I am in first year of my Bachelor Degree course and have no prior knowledge in programming. How can I get any benefit from the portal?
        </p>
        <p style="color:navy">
            <b>Ans:</b>
            We have many courses which can help people learn technologies/programming languages from basics.
            We create training videos step by step with very basic concepts. 
            If you still have any issues with understanding any thing, you can email us with your comments and we can try to
            recreate video with simpler detail or even can guide you with a live session.
        </p>
    </div>
    <div class="w3-col">
        <p style="color:brown">
            <b>Q: </b>
            What is the benefit of creating a profile with TechCodeLib portal.
        </p>
        <p style="color:navy">
            <b>Ans:</b>
            When you start learning different technologies, we encourage people work on guided projects and submit detail with GitHub Repositories.
            We help you document the project you are working on and this will be displayed on your profile.
            This profile link you can share with other platforms like LinkedIn, Monster.com etc. This helps recruiters/Interviewers know more about
            your technical knowledge.
        </p>
    </div>
    <div class="w3-col">
        <p style="color:brown">
            <b>Q: </b>
            What is GitHub and how is it benefitial to create a profile with GitHub?
        </p>
        <p style="color:navy">
            <b>Ans:</b>
            GitHub is an online code repository which is available for public at free of cost. Software Developers or Teams use GitHub for maintaining their code in GitHub.
            You can make private/public code repositories. Anyone can see and copy/download your code. So, one benefit is that you do not loose your source code
            for your projects as it is always there on web. Secondly you can share it with anyone to review it. Thirdly it is widely used in small to big in 
            many IT/Software organizations. It will help you learn SCM in Software Engineering when you actully work in different companies.
            
            
        </p>
        <p style="color:navy">
            We can access your public Repositories and publish on your profile.            
        </p>
    </div>
    <div class="w3-col">
        <p style="color:brown">
            <b>Q: </b>
            How and what should I start after registering with the portal.
        </p>
        <p style="color:navy">
            <b>Ans:</b>
            You should pick any course of basic level and we keep adding next level videos with every week. You can always check notifications tab on the 
            home page after log in. With every course, we can explain how it is benefitial. If you still have a doubt, send us email or request for 
            quick online help. We, based on such requests, can take live sessions and can help you pick the right course to start with.
        </p>
    </div>
    <div class="w3-col">
        <p style="color:brown">
            <b>Q: </b>
            How and what should I start with after registering with the portal.
        </p>
        <p style="color:navy">
            <b>Ans:</b>
            You should pick any course of basic level and we keep adding next level videos with every week. You can always check notifications tab on the 
            home page after log in. With every course we can explain how it is benefitial. If you still have a doubt send us email or request for 
            quick online help. We, based on such requests, can take live sessions and can help you pick the right course to start with.
        </p>
    </div>
</div>
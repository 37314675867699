import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { certificate } from '../models/certificate';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { AppSettings } from 'src/app/shared/models/appsettings';
@Injectable({
    providedIn: 'root'
})
export class UserCertificateService {
    private appSettings: AppSettings;
    constructor(private http: HttpClient, private appSettingsService: AppSettingsService){
        this.appSettingsService.getSettings().subscribe(data => {
            
            this.appSettings = data;});
    }
    getUserCertificates(userId: number): Observable<certificate[]>{
        let userCertificateUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.getUserCertificates + userId;     
        console.log(userCertificateUrl);   
        return this.http.get<certificate[]>(userCertificateUrl);
    }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { appUserCourse } from 'src/app/courses/models/appUserCourse';
import { appUserCourseDetail } from 'src/app/courses/models/appUserCourseDetail';
import { course } from 'src/app/courses/models/course';
import { AppUser } from 'src/app/security/models/appUser';
import { NewAppUser } from 'src/app/security/models/newAppUser';
import { AppSettings } from 'src/app/shared/models/appsettings';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';


@Injectable({
    providedIn: 'root'
})
export class AppUserService {
    private appSettings: AppSettings;
    constructor(private http: HttpClient, private appSettingsService: AppSettingsService){
        this.appSettingsService.getSettings().subscribe(data => {
            //console.log(data);
            this.appSettings = data;});
    }
    getAppUserDetail(id: number): Observable<NewAppUser>{

        // let appUser: AppUser = {
        //     "bio": "Software Developer interested in web applications development utilizing latest technologies like Angular, Node.js, ASP.Net Core etc.",
        //     "company": "Tech Code Lib, Charlotte, NC",
        //     "email": "nitin.rediye@gmail.com",
        //     "fname": "Nitin",
        //     "gitAccount": "nitin1982",
        //     "linkedInAccount": "https://www.linkedin.com/in/nitin3",
        //     "lname": "Rastogi",
        //     "twitter_username": "https://twitter.com/NitinRastogi3",
        //     "avatar_url": "https://avatars1.githubusercontent.com/u/5372907?v=4",
        //     "blog": "",
        //     "html_url": "",
        //     "password": "",
        //     "secQ1Id": 0,
        //     "seQ1Ans": "",
        //     "secQ2Id": 0,
        //     "seQ2Ans": "",

        // };
        // return of(appUser);

        var appUserDetailUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.users + '/' + id;
        return this.http.get<NewAppUser>(appUserDetailUrl).pipe(catchError(this.handleError));
    }

    getUserCourses(id: number): Observable<appUserCourseDetail[]>{
        var appUserCoursesUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.appUserCourses + '/' + id;
        return this.http.get<appUserCourseDetail[]>(appUserCoursesUrl).pipe(catchError(this.handleError));
    } 

    updateAppUserCourse(appUserCourse: appUserCourse):Observable<number>{
        var appUserCoursesUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.addUpdateUserCourse;
        //console.log(appUserCoursesUrl);
        return this.http.post<number>(appUserCoursesUrl, appUserCourse).pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        console.log(error);
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        let errorMsg =  (error.error != null)? error.error.msg: '';
        if(!errorMsg)
            errorMsg = 'Something bad happened; please try again later.'

        return throwError(
            errorMsg);
    }
    
}

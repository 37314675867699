import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppUserCertificatesComponent } from './components/user-certificates/user-certificates.component';


@NgModule({
    declarations: [AppUserCertificatesComponent],
    imports: [ CommonModule ],
    exports: [AppUserCertificatesComponent],
    providers: [],
})
export class CourseCertificatesModule {}

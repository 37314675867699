import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppUserService } from '../../services/app-user.service';
import { AppUser } from 'src/app/security/models/appUser';
import { course } from 'src/app/courses/models/course';
import { UserNotificationService } from 'src/app/user-notifications/services/user-notification.service';
import { appEvent } from 'src/app/user-notifications/models/appEvents';
import { AppSettings } from 'src/app/shared/models/appsettings';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { NewAppUser } from 'src/app/security/models/newAppUser';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';
import { UserService } from 'src/app/security/services/user.service';

@Component({
  selector: 'app-dashboard-display',
  templateUrl: './dashboard-display.component.html',
  styleUrls: ['./dashboard-display.component.scss']
})
export class DashboardDisplayComponent implements OnInit, AfterViewInit {
  bookmarked: boolean = true;
  inprogress: boolean = false;
  certificates: boolean = false;

  items: Observable<any[]>;
  url: Observable<string>;
  coursesInProgress: course[] = [];
  coursesBookmarked: course[] = [];

  user: NewAppUser;
  userTitle: string = 'My GitHub Repository'
  // constructor(private firestore: AngularFirestore, private afStorage: AngularFireStorage, private route: ActivatedRoute) {

  // }
  events: appEvent[] = [];
  private appSettings: AppSettings;
  tweet: string;
  constructor(private appUserService: AppUserService,
    private route: ActivatedRoute,
    private userNotificationService: UserNotificationService,
    private appSettingsService: AppSettingsService,
    private notificationService: AppNotificationService,
    private userService: UserService,
    private router: Router
    ) { }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
  ngOnInit(): void {
    this.appSettingsService.getSettings().subscribe(data => {
      //console.log(data);
      this.appSettings = data;
    });

    //this.items = this.firestore.collection('items').valueChanges();
    this.userNotificationService.getAllEvents().subscribe(data => this.events = data);
    this.userNotificationService.getPublishedTweet().subscribe(data => this.tweet = data);
    this.route.params.pipe(map(x => x.id)).subscribe(
      (id) => {
        this.coursesInProgress = [];
        //console.log('/upload/profile_' + id + '.jpg');
        //this.url = this.afStorage.ref('upload/profile_' + id + '.jpg').getDownloadURL();

        this.appUserService.getAppUserDetail(+id).subscribe(data => {
          console.log(data);
          if (data.gitAccount && data.gitAccount.indexOf('https://github.com/') == -1)
            data.gitAccount = 'https://github.com/' + data.gitAccount
          
          this.user = data;
          this.user.avatar_url = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.documents + '/' + data.profilePicId;
        },(error) => {
          this.notificationService.addNotification(`${error}`);
          let userId = this.userService.getLocalStorage("AuthToken").userId;
          this.router.navigate(['MyProfile', userId]);
        });

        this.appUserService.getUserCourses(+id).subscribe(data => {
          data.forEach(appUserCourseDetail => {

            //console.log(appUserCourseDetail);

            if(appUserCourseDetail.inProgress){
              this.coursesInProgress.push({
                id: appUserCourseDetail.courseId,
                name: appUserCourseDetail.name,
                description: '',
                level: appUserCourseDetail.contentLevel,
                author: appUserCourseDetail.author,
                authorId: 0,
                language: appUserCourseDetail.language,
                subject: appUserCourseDetail.subject,
                courseDuration: 0,
                rating: 1,
                sections: [],
                inProgress: appUserCourseDetail.inProgress,
                bookmarked: appUserCourseDetail.bookmarked,
                finished: false,
                documentId: 0,
                webLink: '',
                amount: 0
              });
            }           


            if(appUserCourseDetail.bookmarked){
              let indexToRemove = this.coursesBookmarked.findIndex(x => x.id == 0);
              this.coursesBookmarked.splice(indexToRemove, 1);

              this.coursesBookmarked.push({
                id: appUserCourseDetail.courseId,
                name: appUserCourseDetail.name,
                description: '',
                level: appUserCourseDetail.contentLevel,
                author: appUserCourseDetail.author,
                authorId: 0,
                language: appUserCourseDetail.language,
                subject: appUserCourseDetail.subject,
                courseDuration: 0,
                rating: 1,
                sections: [],
                inProgress: appUserCourseDetail.inProgress,
                bookmarked: appUserCourseDetail.bookmarked,
                finished: false,
                documentId: 0,
                webLink: '',
                amount: 0,
                
              });
            }
            

            if (this.coursesInProgress.length == 0) {
              this.coursesInProgress.push({
                id: 0,
                name: 'No Course in Progress',
                description: '',
                level: '',
                author: '',
                authorId: 0,
                language: '',
                subject: '',
                courseDuration: 0,
                rating: 1,
                sections: [],
                inProgress: false,
                bookmarked: false,
                finished: false,
                documentId: 0,
                webLink: '',
                amount: 0
              });
            }
            if (this.coursesBookmarked.length == 0) {
              this.coursesBookmarked.push({
                id: 0,
                name: 'No Bookmarked Course',
                description: '',
                level: '',
                author: '',
                authorId: 0,
                language: '',
                subject: '',
                courseDuration: 0,
                rating: 1,
                sections: [],
                inProgress: false,
                bookmarked: false,
                finished: false,
                documentId: 0,
                webLink: '',
                amount: 0
              })
            };
          }
          );
        });


      });


  }


}

<h3>About us</h3>
<div class="w3-row">    
    <div class="w3-col">
        
        <p style="color:navy">
            <b>About us:</b><br>
            We are a group of professional software developers, architects who group together to create some technical content, which
            can help people learn technologies. 
        </p>
    </div>    
</div>
<div class="w3-row">    
    <div class="w3-col">
        
        <p style="color:navy">
            <b>Our mission:</b><br>
            Our mission is to create one platform where you can learn, share and 
            publish your work.<br> 
            Our mission is to make you prepare your technical profile which can help you in searching for new work.<br>
            Our mission is to make you ready to think out of box and become independent technical consultant and help others.

        </p>
    </div>    
</div>

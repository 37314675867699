<table class="w3-table">
    <thead>
        <tr class="w3-light-grey">
            <th>Name</th>
            <th>Subject</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let course of courses">
            <td>{{ course.name }}</td>
            <td>{{ course.subject }}</td>
            <td *ngIf="course.id > 0">
                <a [routerLink]="[ '/Courses', course.id]"
                    class="w3-bar-item w3-button w3-hide-small w3-padding-small w3-hover-white" title="View">
                    <i class="fa fa-external-link-square" style="font-size:25px"></i>
                </a>    
                <!-- <a [routerLink]="[ '/Browse']"
                class="w3-bar-item w3-button w3-hide-small w3-padding-small w3-hover-white" title="View">
                <i *ngIf="!course.bookmarked" class="fa fa-bookmark-o" style="font-size:25px"></i>
                <i *ngIf="course.bookmarked" class="fa fa-bookmark" style="font-size:25px"></i>
                </a>     -->
            </td>
        </tr>       
    </tbody>
</table>

<!-- 
<div class="w3-card-4">
    <header class="w3-container">
        <div class="w3-row">
            <div class="w3-col s9 m9 l9">
                <p>{{ course.name }}</p>                
            </div>
            <div class="w3-col s1 m1 l1 w3-right-align w3-small">
                <h6 class="w3-text-red">{{ course.subject }}</h6>                
            </div>
            <div class="w3-col s2 m2 l2 w3-right-align w3-padding-small">                        
                <a [routerLink]="[ '/Courses', course.id]"
                    class="w3-bar-item w3-button w3-hide-small w3-padding-small w3-hover-white" title="View">
                    <i class="fa fa-external-link-square" style="font-size:25px"></i>
                </a>    
                <a [routerLink]="[ '/Browse']"
                class="w3-bar-item w3-button w3-hide-small w3-padding-small w3-hover-white" title="View">
                <i *ngIf="!course.bookmarked" class="fa fa-bookmark-o" style="font-size:25px"></i>
                <i *ngIf="course.bookmarked" class="fa fa-bookmark" style="font-size:25px"></i>
                </a>           
            </div>
        </div>        
    </header>   
</div> -->

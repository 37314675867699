import { Component, OnInit, ɵSafeResourceUrl } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { course } from '../../models/course';
import { CoursesService } from '../../services/courses.service';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { UserService } from 'src/app/security/services/user.service';
import { appUserCourse } from '../../models/appUserCourse';
import { AppUserService } from 'src/app/dashboard/services/app-user.service';
import { courseQuiz } from '../../models/courseQuiz';
import { courseAssignment } from '../../models/courseAssignment';
import { AppSettings } from 'src/app/shared/models/appsettings';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';


@Component({
    selector: 'app-view-course',
    templateUrl: './view-course.component.html',

})
export class ViewCourseComponent implements OnInit {
    course: course;
    documentUrl: string = "";
    sourceCode: string = "";
    courseQuiz: courseQuiz;
    courseAssignment: courseAssignment;
    appSettings: AppSettings;
    userId: number;
    someHtml: '<iframe src="https://player.vimeo.com/video/480887706" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
    constructor(private route: ActivatedRoute,
        private router: Router,
        private service: CoursesService,
        private appUserservice: AppUserService,
        private sanitizer: DomSanitizer,
        private userService: UserService,
        private appSettingsService: AppSettingsService,
        private notificationService: AppNotificationService
    ) {
        this.appSettingsService.getSettings().subscribe(data => {

            this.appSettings = data;
        });
        var formData = new FormData();
        console.log(formData.get('referenceId'));
    }

    ngOnInit(): void {
        var formData = new FormData();
        console.log(formData.get('referenceId'));
        this.userId = this.userService.getLocalStorage("AuthToken").userId;
        this.route.paramMap.pipe(
            switchMap((params: ParamMap) =>
                this.service.getCourse(+params.get('id')))
        ).subscribe(

            data => {
                this.course = data;

                this.service.getAppUserCourseDetail(data.id, this.userId).subscribe(appUserCourse => {
                    this.course.bookmarked = appUserCourse.bookmarked;
                });

                this.course.sections.forEach(sec => {
                    this.service.getTheSectionStatusForUser(+this.userId, data.id, sec.id).subscribe(data => {
                        console.log('Test1');
                        
                        sec.enabled = data.enabled;
                        sec.freetrial =  data.freeTrial;
                        sec.freetrialactive = data.freeTrialEnabled;
                        sec.message = data.message;
                        console.log(sec);
                    });
                });
                if(data.documentId)
                    this.documentUrl = `${this.appSettings.techCodeLibAPIBaseUrl}${this.appSettings.documents}/${data.documentId}`;
                this.sourceCode = data.webLink;

                this.course.sections.forEach(sec => sec.document =(sec.documentId)? `${this.appSettings.techCodeLibAPIBaseUrl}${this.appSettings.documents}/${sec.documentId}`: '')

                //console.log(data);
                this.service.getCourseQuiz(+this.course.id, +this.userId).subscribe(quiz => {
                    this.courseQuiz = quiz;
                    console.log(this.courseQuiz);
                });

                this.service.getCourseAssignment(+this.course.id, +this.userId).subscribe(assignment => {
                    this.courseAssignment = assignment;
                    console.log(this.courseAssignment);
                });
            }
        );
    }

    getUrl(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    expandCollapseQuiz(ctrl) {

        var x = ctrl;//document.getElementById(id);

        if (x.className.indexOf("w3-show") == -1) {
            console.log('1');
            x.className += " w3-show";
        } else {
            console.log('2');
            x.className = x.className.replace(" w3-show", " w3-hide");
        }
        return false;
    }
    expandCollapseAssignment(ctrl) {

        var x = ctrl;//document.getElementById(id);

        if (x.className.indexOf("w3-show") == -1) {
            console.log('1');
            x.className += " w3-show";
        } else {
            console.log('2');
            x.className = x.className.replace(" w3-show", " w3-hide");
        }
        return false;
    }
    expandCollapseSection(ctrl) {
        
        var x = ctrl;//document.getElementById(id);
        if (x.className.indexOf("w3-show") == -1) {
            x.className += " w3-show";
        } else {
            x.className = x.className.replace(" w3-show", " w3-hide");
        }
        return false;
    }
    expandCollapseVideo(ctrl, sectionEnabled, msg, freeTrialEnabled) {
        // console.log(sectionEnabled)
        // console.log(msg)
        // console.log(freeTrialEnabled)
        //console.log(freeTrialEnabled)
        let inprgrs=true;
        if (!sectionEnabled && msg && !freeTrialEnabled) {
            this.notificationService.addNotification(msg);
        }else if(!sectionEnabled && !msg && !freeTrialEnabled){
            this.notificationService.addNotification('Please enable a free trial or make a payment.');
            inprgrs=false;
        } 
        else if(!sectionEnabled && freeTrialEnabled && msg){
            this.notificationService.addNotification(msg);
            inprgrs=true;
            //console.log('msg')
            var x = ctrl;//document.getElementById(id);
            if (x.className.indexOf("w3-show") == -1) {
                x.className += " w3-show";
            } else {
                x.className = x.className.replace(" w3-show", " w3-hide");
            }
        }
        else {           
            console.log('msg')
            var x = ctrl;//document.getElementById(id);
            if (x.className.indexOf("w3-show") == -1) {
                x.className += " w3-show";
            } else {
                x.className = x.className.replace(" w3-show", " w3-hide");
            }
            
        }
        if (!this.course.inProgress) {
            let appUserCourse: appUserCourse =
            {
                appUserId: this.userId,
                courseId: this.course.id,
                inProgress: inprgrs,
                bookmarked: this.course.bookmarked,
                finished: this.course.finished
            };
            this.appUserservice.updateAppUserCourse(appUserCourse).subscribe(id => console.log(id));
            this.course.inProgress = (inprgrs)?!this.course.inProgress: this.course.inProgress;
        }
        //console.log('Test1');
        return false;
    }

    EnebleFreeTrial(sectionId){
        
        if(confirm('Are you sure you want to start free trial? You can use maximum one section free per course'))
        {
            //console.log('Testing')
            this.service.EnableFreeTrial(this.userId, this.course.id, sectionId).subscribe(data => {
                console.log(data);
                if(data.freeTrialEnabled || data.enabled)
                {
                    this.notificationService.addNotification(data.message);
                    this.course.sections.forEach(x => {
                        x.freetrial = false;
                        if(x.id == data.sectionId)
                        {                           
                            x.freetrialactive = true;
                        }else{                         
                            x.freetrialactive = false;
                        }

                    });                    
                }
                
            });
        }
        return false;
    }
}

<div class="w3-card">
    <header class="w3-theme-d2">
        <div class="w3-container">
            <h2>Reset Password</h2>
        </div>
    </header>
    <form novalidate class="w3-container w3-half" #frmResetPassword="ngForm" (ngSubmit)="Submit()">
        <div class="w3-row w3-section">
            <div class="w3-col w3-third">
                <label class="w3-text-teal"><b>User Id/Email</b></label>
            </div>
            <div class="w3-col w3-twothird">
                <input class="w3-input w3-border" [ngClass]="{'w3-border-red': userName.invalid}" name="email"
                    type="email"
                    pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                    [(ngModel)]="resetPassword.email" required #userName="ngModel" placeholder="Enter valid email id">
                <div *ngIf="userName.invalid" style="color: red;">Enter userId/email id</div>
            </div>

        </div>
        <div class="w3-row w3-section" *ngIf="!detailFound">
            <div class="w3-col w3-third">
                &nbsp;
            </div>
            <div class="w3-col w3-twothird">
                <input type="button" class="w3-btn w3-teal" (click)="FetchDetail(userName.value)" value="Enter" />
            </div>
        </div>
        <div class="w3-row w3-section" *ngIf="detailFound">
            <div class="w3-col w3-third">
                <label class="w3-text-teal"><b>Password</b></label>
            </div>
            <div class="w3-col w3-twothird">
                <input class="w3-input w3-border" [ngClass]="{'w3-border-red': password.invalid}" name="password"
                    type="text" minlength="8" [(ngModel)]="resetPassword.password" required #password="ngModel"
                    placeholder="Enter Password">
                <div *ngIf="password.invalid" style="color: red;">Enter valid password</div>
            </div>
        </div>
        <div class="w3-row w3-section" *ngIf="detailFound">
            <div class="w3-col w3-third">
                <label class="w3-text-teal"><b>Secret Question 1</b></label>
            </div>
            <div class="w3-col w3-twothird">
                <input type="text" id="secretQuestion1" name="secretQuestion1" #secretQuestion1="ngModel"
                    disabled
                    [(ngModel)]="resetPassword.secretQuestion1" class="w3-input w3-border" />
                <br>
                <input class="w3-input w3-border" [ngClass]="{'w3-border-red': answer1.invalid}" required name="answer1"
                    [(ngModel)]="resetPassword.answer1" #answer1="ngModel" placeholder="Enter Secret Question 1 Answer">
                <div *ngIf="answer1.invalid" style="color: red;">Enter Answer for Secret Question 1</div>
            </div>
        </div>

        <div class="w3-row w3-section" *ngIf="detailFound">
            <div class="w3-col w3-third">
                <label class="w3-text-teal"><b>Secret Question 2</b></label>
            </div>
            <div class="w3-col w3-twothird">
                <input type="text" id="secretQuestion2" name="secretQuestion2" #secretQuestion2="ngModel"
                    
                    [(ngModel)]="resetPassword.secretQuestion2" class="w3-input w3-border" disabled />

                <br>
                <input class="w3-input w3-border" name="answer2" [(ngModel)]="resetPassword.answer2"
                    [ngClass]="{'w3-border-red': answer2.invalid}" required #answer2="ngModel"
                    placeholder="Enter Secret Question 2 Answer">
                <div *ngIf="answer2.invalid" style="color: red;">Enter Answer for Secret Question 2</div>
            </div>
        </div>

        <div class="w3-row w3-section" *ngIf="detailFound">
            <div>
                <input type="submit" class="w3-btn w3-theme-d2" [disabled]="frmResetPassword.invalid" value="Save">
                <br />
                <br />
            </div>
        </div>
        <!-- <div>
            <pre>{{ frmLogin.value | json }}</pre>
        </div> -->
    </form>

</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { careerPath } from '../../models/careerPath';
import { CareerPathService } from '../../services/career-path.service';

@Component({
    selector: 'app-career-path-detail',
    templateUrl: './career-path-detail.component.html'    
})
export class CareerPathDetailComponent implements OnInit {
    careerPath: careerPath;
    constructor(private route: ActivatedRoute, private careerPathService: CareerPathService) { 
        this.careerPath = {id: 0, name: '', detail: ''};
    }

    ngOnInit(): void { 
        this.careerPath = {id: 0, name: '', detail: ''};
        this.route.params.pipe(map(x => x.id)).subscribe(
            (id) => {
                this.careerPathService.getCareerPathDetail(+id).subscribe(data => this.careerPath = data);
            }
        );
    }
}

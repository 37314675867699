import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { BrowseCoursesComponent } from './components/browse-courses/browse-courses.component';
import { ViewCourseComponent } from './components/view-course/view-course.component';
import { CourseComponent } from './components/course/course.component';
import { RouterModule } from '@angular/router';
import { DashboardCourseComponent } from './components/dashboard-course/dashboard-course.component';
import { AllCoursesComponent } from './components/all-courses/all-courses.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { FormsModule } from '@angular/forms';
import { AssignmentComponent } from './components/assignment/assignment.component';

@NgModule({
    declarations: [BrowseCoursesComponent, ViewCourseComponent, CourseComponent, 
        DashboardCourseComponent, AllCoursesComponent, QuizComponent, AssignmentComponent],
    imports: [ CommonModule, BrowserModule, RouterModule, FormsModule ],
    exports: [BrowseCoursesComponent, ViewCourseComponent, CourseComponent, DashboardCourseComponent],
    providers: [],
})
export class CoursesModule {}
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class AppNotificationService {
    messages: Subject<string>=new Subject<string>();
    

    addNotification(message: string){
        this.messages.next(message);
    }
}
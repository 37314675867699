// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //techCodeLibAPIBaseUrl: "https://localhost:56856",
  //techCodeLibAPIBaseUrl: "https://techcodelibwebapi.azurewebsites.net",
  //techCodeLibAPIBaseUrl: "https://b2yd0aaq59.execute-api.us-east-1.amazonaws.com/Prod",
  //techCodeLibAPIBaseUrl: "http://techc-recip-1xwhmwmgzs25x-903178757.us-east-1.elb.amazonaws.com",  
  techCodeLibAPIBaseUrl: "https://nitin0305-001-site1.etempurl.com",
  firebase: {
    apiKey: "AIzaSyAZwtO7sHgFnwsyTMW84pYkQHsbOY8FXcY",
    authDomain: "awesomeproject-a8f41.firebaseapp.com",
    databaseURL: "https://awesomeproject-a8f41.firebaseio.com",
    projectId: "awesomeproject-a8f41",
    storageBucket: "awesomeproject-a8f41.appspot.com",
    messagingSenderId: "1021752488416",
    appId: "1:1021752488416:web:36a304c66df2d9196a3b85"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

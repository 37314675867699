import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DailyCodeSampleComponent } from './components/dailyCodeSamples/daily-code.component';

@NgModule({
    declarations: [DailyCodeSampleComponent],
    imports: [ CommonModule ],
    exports: [],
    providers: [],
})
export class DailyCodeSamplesModule {}

<div class="w3-card-4">
    <header class="w3-container w3-light-grey">
        <div class="w3-row">
            <div class="w3-col s8 m8 l8">
                <h4>{{ course.name }}</h4>
                <p class="w3-small" style="color: navy;">By {{ course.author }}</p>
            </div>
            <div class="w3-col s2 m2 l2 w3-right-align w3-small">
                <h6 class="w3-text-red">{{ course.subject }}</h6>
                <b>Duration:</b> {{ course.courseDuration }} minutes
            </div>
            <div class="w3-col s2 m2 l2 w3-right-align w3-padding-small">                        
                <a [routerLink]="[ '/Courses', course.id]"
                    class="w3-bar-item w3-button w3-padding-small w3-hover-white" title="View">
                    <i class="fa fa-external-link-square" style="font-size:25px"></i>
                </a>
                <a (click)="markBookmark()"
                class="w3-bar-item w3-button w3-padding-small w3-hover-white" title="Bookmark/Unbookmark a Course">
                <i *ngIf="!course.bookmarked" class="fa fa-bookmark-o" style="font-size:25px"></i>
                <i *ngIf="course.bookmarked" class="fa fa-bookmark" style="font-size:25px"></i>
                </a>
            </div>
        </div>
        
    </header>
    <div class="w3-container">
        <div class="w3-row">
            <div class="w3-col s6 m6 l6">
                <p>{{ course.description }}</p>
            </div>                   
        </div>                
    </div>
</div>
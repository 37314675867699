import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { careerPath } from '../models/careerPath';

@Injectable({
    providedIn: 'root'
})
export class CareerPathService {
    private careerPaths: careerPath[] = [
        {
            id: 1, 
            name: 'Full Stack Web Developer', 
            detail: `A Full Stack web developer is one who can work on both Front end as well as Backend Technologies. 
            You can do trainings like Java Script, Angular, Vue.js, React, Bootstrap etc for Front end technologies and ASP.Net MVC, C#, ASP.Net CORE REST API, Node.js, Entity Framework etc under this career path.`
        },
        {
            id: 2, 
            name: 'Windows Applications Development with C#', 
            detail: `Windows Forms and Interactive Desktop apps using Windows Presentation Foundation(WPF) are created as part of this career path. You should learm C# Programming Language, Winforms Apps, WPF Apps, SQL Server
            Entity Framework, LINQ under this career path.`
        },
        {
            id: 3, 
            name: 'Create Server API with Node.js', 
            detail: `Learn Server side technology Node.js and learn server API with this career path. You should have some knowledge of JavaScript before you start working on Node.`
        },
        {
            id: 4, 
            name: 'REST Web API with ASP.Net CORE', 
            detail: `Learn Server side technology ASP.Net Core Web API with this career path. You should have basic knowledge of C# programming language, SQL Server, Entity Framework before you start working on this.`
        },
        {
            id: 5, 
            name: 'ETL with SSIS', 
            detail: `Sometimes you may need to integrate multiple systems. Data is extracted from one system, transformed and loaded to a different system. This process is called ETL(Extract Transform Load). You can learn Microsoft Technology SSIS(SQL Server Integration Services) under this career path. You should have knowledge of SQL Server db before you start this.`
        },
        {
            id: 6, 
            name: 'Modern UI Tech. Angular Apps', 
            detail: `Angular is one of the most popular and widely used UI technology from Google. You can develop high performance, nice interactive Single Page Applications(SPAs) with this career path.
                    You should have good knowledge of JavaScript before you start this.`
        },
        {
            id: 7, 
            name: 'Modern Tech. React Apps', 
            detail: `REACT is one of the most popular and widely used UI technology from Facebook. You can develop high performance, nice interactive Single Page Applications(SPAs) with this career path.
            You should have good knowledge of JavaScript before you start this.`
        },
        {
            id: 8, 
            name: 'Modern Tech. Mongo DB', 
            detail: `Learn NoSQL and Document based database Mongo DB with this career path.`
        },
        {
            id: 9, 
            name: 'Modern Tech. Vue.js', 
            detail: `Learn Moderen UI technology Vue.js with this career path. You can build light weight. high performance Apps like websites with this technology.`
        },
        {
            id: 10, 
            name: 'Web design', 
            detail: `Learn Web design with CSS, Bootstrap with this career path.`
        },
        {
            id: 11, 
            name: 'Cloud Technologies', 
            detail: `Learn MS Azure, AWS with this career path.`
        },
        {
            id: 12, 
            name: 'Data Structure and Algo.', 
            detail: `Learn Data Structure and Algo with this career path.`
        },
        {
            id: 13, 
            name: 'DB Access with Entity Framework', 
            detail: `Learn DB Access with Entity Framework with this career path.`
        }
    ];

    constructor(){
       
    }
    getAllCareerPaths():Observable<careerPath[]>{
        return of(this.careerPaths);
    }

    getCareerPathDetail(id: number): Observable<careerPath>{
        return of(this.careerPaths.filter(x => x.id === id)[0]);
    }
}
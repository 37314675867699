<div class="w3-container" *ngIf="course">
    <h2>{{ course.name }}</h2>
    <p>{{ course.description }}</p>
    <a *ngIf="documentUrl" [href]="documentUrl" target="_blank"
                    class="w3-bar-item w3-button  w3-padding-small" title="Download">
                    <i class="fa fa-download" style="font-size:25px"></i>
    </a>      
    <a [href]="sourceCode" target="_blank"
                    class="w3-bar-item w3-button  w3-padding-small" title="Source Code">
                    <i class="fa fa-code" style="font-size:25px"></i>
    </a>  
    <div *ngFor="let section of course.sections;let indexOfelement=index;" class="w3-margin-bottom">
        <button (click)="expandCollapseSection(Demo1)" class="w3-btn w3-block w3-theme-d2 w3-left-align">
            Section {{ indexOfelement+1 }} - {{ section.name }}&nbsp;&nbsp;
            <span style="color: sandybrown; font-size: small;">Amount {{ section.amount | currency:"INR":"symbol" }}</span>&nbsp;&nbsp;
            <i class="fa fa-chevron-down"></i>
        </button>
        <div id="Demo1" #Demo1 name="Demo1" class="w3-container w3-hide">
            <div class="w3-container">
                <p>{{ section.description }} &nbsp;&nbsp;
                    <br>
                    <span *ngIf="!section.enabled && !section.freetrialactive" style="color: brown; font-size: small;">
                        (Click on next payment icon to make a payment to enable this section)</span>
                        &nbsp;
                    <input type="button" *ngIf="section.freetrial" 
                    [disabled]="section.freetrialactive"
                    (click)="EnebleFreeTrial(section.id)" value="Free Trial" />
                    <!-- <a *ngIf="section.freetrial" href="#" (click)="EnebleFreeTrial(section.id)"  target="_blank"
                        style="text-decoration: none;" title="Make a payment">
                        <mark style="font-weight: bolder;">Free Trial</mark>
                    </a> -->

                    <a *ngIf="!section.enabled && !section.freetrialactive" [routerLink]="[ '/MakePayment', course.id, section.id ]" 
                        class="w3-bar-item w3-button w3-padding-small" title="Make a payment">
                        <i class="fa fa-credit-card" style="font-size:25px"></i>
                    </a>

                    <a *ngIf="section.documentId != null" [href]="section.document" target="_blank"
                    class="w3-bar-item w3-button w3-padding-small" title="Download">
                    <i class="fa fa-download" style="font-size:25px"></i>
                </a>      
                <a *ngIf="section.webLink" [href]="section.webLink" target="_blank"
                                class="w3-bar-item w3-button  w3-padding-small" title="Source Code">
                                <i class="fa fa-code" style="font-size:25px"></i>
                </a>  </p>
                
            </div>

            <div *ngFor="let video of section.videos" class="w3-row">
                <div class="w3-col s1 m1 l1">

                </div>
                <div class="w3-col s11 m11 l11">
                    <a href="#" (click)="expandCollapseVideo(DemoVideo, section.enabled, section.message, section.freetrialactive)" 
                        class="w3-padding-small" style="text-decoration: none;font-weight: bolder;" title="Click here to open Video">
                        <i class="fa fa-play-circle-o" style="font-size:20px"></i>&nbsp;{{ video?.name }}</a>
                    <div class="w3-container">
                        <p>{{ video.description }}</p>
                    </div>
                    <div id="DemoVideo" #DemoVideo name="DemoVideo" [innerHtml]="getUrl(video.embedLink)"
                        class="w3-container w3-hide w3-padding-16">
                    </div>
                </div>
            </div>
            <div>
                <hr>
            </div>
        </div>

    </div>
    <div *ngIf="courseQuiz" class="w3-margin-bottom">
        <button (click)="expandCollapseQuiz(DivQuiz)" 
            class="w3-btn w3-block w3-theme-d2 w3-left-align">
            Quiz -  {{ courseQuiz.quiz }}&nbsp;&nbsp;&nbsp;&nbsp;
            <i class="fa fa-chevron-down"></i>
        </button>
        <div id="DivQuiz" #DivQuiz name="DivQuiz" class="w3-container w3-hide">
            <div class="w3-row">
                <app-quiz [quizQuestions]="courseQuiz.quizQuestions"></app-quiz>
            </div>            
        </div>
        <!-- <div id="DivAssignment" #DivQuiz name="DivAssignment" class="w3-container w3-hide">
            <div class="w3-row">
                <app-quiz [assignmentTasks]="courseQuiz.quizQuestions"></app-quiz>
            </div>            
        </div> -->
    </div>
    <div *ngIf="courseAssignment">
        <button (click)="expandCollapseAssignment(DivAssignment)" 
            class="w3-btn w3-block w3-theme-d2 w3-left-align">
            Assignment -  {{ courseAssignment.assignment }}&nbsp;&nbsp;&nbsp;&nbsp;
            <i class="fa fa-chevron-down"></i>
        </button>
       
        <div id="DivAssignment" #DivAssignment name="DivAssignment" class="w3-container w3-hide">
            <div class="w3-row">
                <app-assignment [assignmentTasks]="courseAssignment.assignmentTasks"></app-assignment>
            </div>            
        </div>
    </div>
</div>
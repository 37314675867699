<app-navigation></app-navigation>

<!-- Page Container -->
<div class="w3-container w3-content" style="max-width:1400px;margin-top:80px;min-height:700px">
  <router-outlet></router-outlet>

</div>
<br>
<br>

<div>
  <footer class="w3-center">
    <div class="w3-theme-d2 w3-large">
      <a href="#" style="pointer-events: none;text-decoration: none;" class="w3-button w3-padding-large w3-hover-white">TechCodeLib @ 2021</a>
      <a href="#"  [routerLink]="[ '/About']" routerLinkActive="w3-white"  class="w3-button w3-padding-large w3-hover-white">About us</a>
      <!-- <a href="#" class="w3-button w3-hide-small w3-padding-large w3-hover-white">Privacy Policy</a>
      <a href="#" class="w3-button w3-hide-small w3-padding-large w3-hover-white">Cookie Policy</a> -->
    </div>   
  </footer>
</div>
<app-notification></app-notification>

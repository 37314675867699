import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/shared/models/appsettings';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { socialMediaPost } from '../models/socialMediaPost';

@Injectable({
    providedIn: 'root'
})
export class SocialMediaService {
    private appSettings: AppSettings;
    constructor(private http: HttpClient, private appSettingsService: AppSettingsService){
      console.log('SocialMediaService constuctor');
      this.appSettingsService.getSettings().subscribe(data => {
        //console.log(data);
        this.appSettings = data;});
    }
    getAllSocialMediaPosts(): Observable<socialMediaPost[]>{
        let socialMediaUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.getAllSocialMediaPosts;
         
        return this.http.get<socialMediaPost[]>(socialMediaUrl);
    }
}
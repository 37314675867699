import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, Route } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {

    constructor(private userService: UserService, private router: Router){}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):boolean {
        return this.checkUserLoggedIn(state.url);
    }

    canLoad(route: Route): boolean{
        return this.checkUserLoggedIn(route.path);
    }

    checkUserLoggedIn(url: string) {
        console.log(this.userService.isLoggedIn);
        if(!this.userService.isLoggedIn){
            this.userService.redirectUrl = url;
            this.router.navigate(['Home']);
            return false;
        }
        console.log(this.userService.loggedInAppUser);
        return true;
        
    }

}
import { Component, OnInit } from '@angular/core';
import { AppUserService } from 'src/app/dashboard/services/app-user.service';
import { UserService } from 'src/app/security/services/user.service';
import { course } from '../../models/course';
import { CoursesService } from '../../services/courses.service';



@Component({
    selector: 'app-browse-courses',
    templateUrl: './browse-courses.component.html',    
})
export class BrowseCoursesComponent implements OnInit {
    courses: course[];
    userCourseIds: number[];
    constructor(private service: AppUserService, private courseService: CoursesService, private userService: UserService) { }

    ngOnInit(): void {
        let userId = this.userService.getLocalStorage("AuthToken").userId;

        this.service.getUserCourses(+userId).subscribe(data => {
            //console.log(data);
            this.userCourseIds = data.map(x => x.courseId);
            
            //console.log(this.userCourseIds);
            this.courseService.getAllDisplayCourses().subscribe(courses => {
                this.courses = courses; 
                this.courses.forEach(element => {                    
                    if(this.userCourseIds.indexOf(element.id) != -1)
                    {                        
                        let appUserCourse = data.filter(x => x.courseId == element.id)[0];
                        element.bookmarked = appUserCourse.bookmarked;
                        element.inProgress = appUserCourse.inProgress;
                        element.finished = appUserCourse.finished;
                    }                        
                });
                //console.log(this.courses);               
            });

            
        });
    }
}

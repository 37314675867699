import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CareerPathDetailComponent } from './components/career-path-detail/career-path-detail.component';

@NgModule({
    declarations: [CareerPathDetailComponent],
    imports: [ CommonModule ],
    exports: [],
    providers: [],
})
export class CareerPathModule {}

import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
// import { AffiliateNotificationService } from 'src/app/shared/services/affiliate-notification.service';
import { AppSettings } from 'src/app/shared/models/appsettings';
// import { AffiliateAppSettingsService } from 'src/app/shared/services/affiliate-settings.service';
// import { HelpEmailComponent } from 'src/app/shared/components/help-email/help-email.component';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { LoggedInAppUser } from '../../models/loggedInAppUser';
import { AppUser } from '../../models/appUser';
import { NewAppUser } from '../../models/newAppUser';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';

declare const gitLogin: any;
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    @ViewChild('frmLogin') frm : any;
    user: User;    
    //forgotPassword: boolean = false;
    signingInProgress: boolean = false;
    loggedIn: boolean = false;
    
    private settings: AppSettings;
    constructor(private userService: UserService, private router: Router, 
        private notificationService: AppNotificationService) { 
            //this.appSettingsService.getSettings().subscribe(data => this.settings = data);
    }

    ngOnInit(): void { 
        this.user = {username: '', password: ''};                
    }

    
    Submit(){      
        this.signingInProgress = true;
        let user: User = this.frm.value as User;     
        console.log(user);
            
        if(this.frm.valid){
            this.userService.login(user).subscribe(data => {
                this.signingInProgress = false;
                    this.Clear();     
                    //console.log(data);
                    
                    if(data){
                        if(!this.userService.redirectUrl)
                            this.userService.redirectUrl = `MyProfile/${data.userId}`; 
                        // if(this.userService.redirectUrl){
                        //     this.userService.redirectUrl = `Home`;
                        // }   
                        
                        this.router.navigate([this.userService.redirectUrl]);
                    }
                    
            },(error) => {
                this.signingInProgress = false;
                this.notificationService.addNotification(`${error}`);     
                this.Clear();               
              });
        }
         
        // if(this.forgotPassword){
        //     // if(user.userName == undefined || user.userName == ''){
        //     //     this.alertService.error(this.settings.incorrectCredentialsForgotPasswordMsg);                              
        //     // }else{
        //     //     this.userService.forgotPassword(user.userName).subscribe(data => {
        //     //         if(data.Success)
        //     //             this.alertService.info(this.settings.passwordSentToEmailSuccessfullyMsg, false);
        //     //     });  
        //     //     this.Clear();   
        //     // }
        // }
    }

    loginGit(){
        gitLogin(environment.firebase).then(data => {
            console.log(data);
            let loggedInUser: NewAppUser = {
                avatar_url: '',
                bio: '',
                //blog: '',
                fname: '',
                lname: '',
                email: '',    
                //html_url: '',
                twitter: '',
                company: '',
                password: 'Test@123',
                gitAccount: '',
                linkedInAccount: '',
                secretQuestions: [],
                gitLogIn: true,
                profilePicId:1,
                id: 0
            };

            loggedInUser.avatar_url = data.avatar_url;
            loggedInUser.bio = data.bio;
            //loggedInUser.blog = data.blog;
            if(!data.name)
            {

            
                loggedInUser.fname = data.login;
                loggedInUser.lname = "NoLastName";
            }
            else
            {
                loggedInUser.fname = data.name.split(' ')[0];
                loggedInUser.lname = data.name.replace(loggedInUser.fname, '');
            }
            if(data.email)
                loggedInUser.email = data.email;
            else
                loggedInUser.email = 'no-email-set@tech-code-lib.com';
            //loggedInUser.html_url = data.html_url;
            loggedInUser.twitter = data.twitter_username;
            loggedInUser.company = data.company;
            loggedInUser.gitAccount = data.html_url;
            console.log(loggedInUser);
            this.userService.gitLogin(loggedInUser).subscribe(data => {
                this.signingInProgress = false;
                    console.log(data);
                    
                    if(data){
                        if(!this.userService.redirectUrl)
                            this.userService.redirectUrl = `MyProfile/${data.userId}`; 
                        // if(this.userService.redirectUrl){
                        //     this.userService.redirectUrl = `Home`;
                        // }   
                        
                        this.router.navigate([this.userService.redirectUrl]);
                    }
                    
            });
        });
        
    };
    
    Clear(){
        this.signingInProgress = false;
        this.frm.reset();
    }

    
}

import { Component, Input, OnInit } from '@angular/core';
import { course } from '../../models/course';

@Component({
    selector: 'app-dashboard-course',
    templateUrl: './dashboard-course.component.html'
})
export class DashboardCourseComponent implements OnInit {
    @Input('courses')
    courses: course[];
    constructor() { }

    ngOnInit(): void { }
}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-all-courses',
    templateUrl: './all-courses.component.html'    
})
export class AllCoursesComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}

import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppSettings } from 'src/app/shared/models/appsettings';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { appUserCourse } from '../models/appUserCourse';
import { assignmentResponse } from '../models/assignmentResponse';
import { course } from '../models/course';
import { courseAssignment } from '../models/courseAssignment';
import { courseQuiz } from '../models/courseQuiz';
import { enabledCourseSection } from '../models/enabledCourseSection';
import { quizQuestionResponse } from '../models/quizQuestionResponse';

@Injectable({
    providedIn: 'root'
})
export class CoursesService {
    private appSettings: AppSettings;
    constructor(private http: HttpClient, private appSettingsService: AppSettingsService){
      console.log('CoursesService constuctor');
      this.appSettingsService.getSettings().subscribe(data => {
        //console.log(data);
        this.appSettings = data;});
    }

    getAllDisplayCourses(): Observable<course[]>
    {
        let coursesUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.courses;
         
        return this.http.get<course[]>(coursesUrl);
    }

    getCourse(id: number): Observable<course>
    {
        let coursesUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.courses;
        return this.http.get<course>(`${coursesUrl}/${id}`);
    }

    getCourseQuiz(courseId: number, userId: number): Observable<courseQuiz>{
        let courseQuizUrl = this.appSettings.techCodeLibAPIBaseUrl + 
            this.appSettings.courseQuizs;
        return this.http.get<courseQuiz>(`${courseQuizUrl}?courseId=${courseId}&userId=${userId}`);
    }

    getAppUserCourseDetail(courseId: number, userId: number): Observable<appUserCourse>{
        let appUserCourseDetailUrl = this.appSettings.techCodeLibAPIBaseUrl + 
        this.appSettings.getAppUserCourseDetail;
        return this.http.get<appUserCourse>(`${appUserCourseDetailUrl}?courseId=${courseId}&userId=${userId}`);
    }

    getCourseAssignment(courseId: number, userId: number): Observable<courseAssignment>{
        let courseAssignmentUrl = this.appSettings.techCodeLibAPIBaseUrl + 
            this.appSettings.courseAssignments;
        return this.http.get<courseAssignment>(`${courseAssignmentUrl}?courseId=${courseId}&userId=${userId}`);
    }
    getAllAppUserCoursesByUserId(id: number): Observable<appUserCourse[]>{
        // let appUserCoursesUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.appUserCourses + "/" + id;
        // return this.http.get<appUserCourse[]>(appUserCoursesUrl);

        return of([{
            appUserId: 1,
            bookmarked: false,
            courseId: 1,
            finished: false,
            id: 1,
            inProgress: false}]);
    }

    postQuizResponse(quizQuestions: quizQuestionResponse[]): 
        Observable<quizQuestionResponse[]>{
        
        let addUpdateQuizResponseUrl = 
            this.appSettings.techCodeLibAPIBaseUrl + 
            this.appSettings.courseQuizResponse;        

        return this.http.post<quizQuestionResponse[]>(
            addUpdateQuizResponseUrl,quizQuestions)
            .pipe(catchError(this.handleError));
    }

    postAssignmentResponse(assignmentTasks: assignmentResponse[]): 
    Observable<assignmentResponse[]>{
    
    let addUpdateQuizResponseUrl = 
        this.appSettings.techCodeLibAPIBaseUrl + 
        this.appSettings.courseQuizResponse;        

    return this.http.post<assignmentResponse[]>(
        addUpdateQuizResponseUrl,assignmentTasks)
        .pipe(catchError(this.handleError));
    }

    getTheSectionStatusForUser(userId: number, courseId: number, sectionId: number): Observable<enabledCourseSection>{
        let getSectionStatusForUserUrl = `${this.appSettings.techCodeLibAPIBaseUrl}${this.appSettings.isSectionEnableForUser}?userId=${userId}&courseId=${courseId}&sectionId=${sectionId}`;        

        return this.http.get<enabledCourseSection>(getSectionStatusForUserUrl)
        .pipe(catchError(this.handleError));
    }

    EnableFreeTrial(userId: number, courseId: number, sectionId: number): Observable<enabledCourseSection>{
        let enableFreeTrialSectionUrl = `${this.appSettings.techCodeLibAPIBaseUrl}${this.appSettings.enableFreeTrial}?userId=${userId}&courseId=${courseId}&sectionId=${sectionId}`;        

        return this.http.post<enabledCourseSection>(enableFreeTrialSectionUrl, null)
        .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        //console.log(error.error);
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        let errorMsg =  (error.error != null)? error.error.msg: '';
        if(!errorMsg)
            errorMsg = 'Something bad happened; please try again later.'
        return throwError(errorMsg);
    }
}
import { Component, OnInit } from '@angular/core';
import { dailyCodeSample } from '../../models/dailyCodeSample';
import { DailyCodeSampleService } from '../../services/daily-Code-Sample.Service';

@Component({
    selector: 'app-daily-code-sample',
    templateUrl: './daily-code-sample.component.html',
    
})
export class DailyCodeSampleComponent implements OnInit {

    liveCodeSamples: dailyCodeSample[]
    constructor(private dailyCodeSampleService: DailyCodeSampleService) { 
        
    }

    ngOnInit(): void { 
        this.dailyCodeSampleService.getAllDailyCodeSamples().subscribe(
            data => this.liveCodeSamples = data
        );
    }
}

<h4>{{ title }}</h4>
<table class="w3-table">
    <thead>
        <tr class="w3-light-grey">
            <th>Name</th>
            <th>Description</th>
            <th>View</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="!codeRepos">
            <td colspan="3" style="color: red;">No Repositories Found For Current User</td>
        </tr>
        <tr *ngFor="let repo of codeRepos">
            <td>{{ repo.name }}</td>
            <td>{{ repo.description }}</td>
            <td>
                <a [href]="repo.html_url" target="_blank"
                    class="w3-bar-item w3-button w3-padding-small w3-hover-white" title="View">
                    <i class="fa fa-external-link-square" style="font-size:25px"></i>
                </a>
            </td>
        </tr>
        <tr>
            <td colspan="3">&nbsp;</td>
        </tr>
    </tbody>
</table>
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from '../about/components/about.component';
import { BlogsComponent } from '../blogs/components/blogs/blogs.component';
import { CareerPathDetailComponent } from '../career-path/components/career-path-detail/career-path-detail.component';
import { CodeReposComponent } from '../code-repos/components/code-repos.component';
import { AllCoursesComponent } from '../courses/components/all-courses/all-courses.component';
import { BrowseCoursesComponent } from '../courses/components/browse-courses/browse-courses.component';
import { ViewCourseComponent } from '../courses/components/view-course/view-course.component';
import { DailyCodeSampleComponent } from '../daily-code-samples/components/dailyCodeSamples/daily-code.component';

import { DashboardDisplayComponent } from '../dashboard/components/dashboard-display/dashboard-display.component';
import { FaqComponent } from '../FAQ/components/faq.component';
import { HomeComponent } from '../home/components/home/home.component';
import { LandingPageComponent } from '../landing-page/components/landing-page.component';
import { SendMessageComponent } from '../messages/send-message.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { MakePaymentComponent } from '../payments/components/make-payment.component';
import { ResetPasswordComponent } from '../security/components/reset-password/reset-password.component';
import { UserRegistrationComponent } from '../security/components/user-registration/user-registration.component';
import { AuthGuardService } from '../security/services/auth-guard.service';
import { UpdatesComponent } from '../updates/updates.component';
import { AppEventComponent } from '../user-notifications/components/event-info.component';


const routes: Routes = [
  {
    path: 'MyProfile/:id',
    component: DashboardDisplayComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    component: LandingPageComponent
  },
  {
    path: 'Home',
    component: HomeComponent
  },
  {
    path: 'UserReg/:id',
    component: UserRegistrationComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'UserReg',
    component: UserRegistrationComponent
  },
  {
    path: 'Browse',
    canActivate: [AuthGuardService],
    component: BrowseCoursesComponent
  },
  {
    path: 'Courses/:id',
    component: ViewCourseComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'CareerPath/:id',
    component: CareerPathDetailComponent
  },
  {
    path: 'Message',
    component: SendMessageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'AllCourses',
    component: AllCoursesComponent    
  },
  {
    path: 'ResetPassword',
    component: ResetPasswordComponent
  },
  {
    path: 'CodeRepositories',
    component: CodeReposComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'MakePayment/:courseId/:sectionId',
    component: MakePaymentComponent
  },
  {
    path: 'MakePayment/:courseId/:sectionId/:msg/:refId',
    component: MakePaymentComponent
  },
  {
    path: 'FAQ',
    component: FaqComponent
  },
  {
    path: 'Updates',
    component: UpdatesComponent
  },
  {
    path: 'About',
    component: AboutComponent
  },
  {
    path: 'EventInfo/:id',
    component: AppEventComponent
  },
  {
    path: 'Blogs',
    component: BlogsComponent
  },
  {
    path: 'TodaysCodeSample',
    component: DailyCodeSampleComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppSettings } from '../models/appsettings';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    constructor(){}

    getSettings(): Observable<AppSettings>{
        let appSettings: AppSettings = new AppSettings();
        return of(appSettings);
    }
}
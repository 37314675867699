import { Component, Input, OnInit } from '@angular/core';
import { CodeRepo } from '../models/codeRepo';
import { CodeReposService } from '../services/code-repos.service';

@Component({
    selector: 'app-code-repos',
    templateUrl: './code-repos.component.html'
})
export class CodeReposComponent implements OnInit {
    @Input('user') user: string='tech-code-lib';
    @Input('title') title: string='Tech-Code-Lib Code Repositories';
    codeRepos: CodeRepo[];
    constructor(private codeRepoService: CodeReposService) { }

    ngOnInit(): void { 
        this.codeRepoService.getPublicRepos(this.user).subscribe(data => this.codeRepos = data);
    }
}


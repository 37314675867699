<!-- The Grid -->
<div class="w3-row">
  <!-- Left Column -->
  <div class="w3-col m3">
    <!-- Profile -->
    <div class="w3-card w3-round w3-white">
      <div class="w3-container" *ngIf="user">
        <h4 class="w3-center">{{ user.fname }} {{ user.lname }}</h4>
        <p class="w3-center"><img  [src]="user.avatar_url" class="w3-circle" style="height:106px;width:106px"
            alt="Avatar">
        </p>
        
        <hr>
        <p>{{ user.bio }} &nbsp;
          <a [routerLink]="[ '/UserReg', user.id ]">
            <i class="fa fa-pencil fa-fw w3-margin-right w3-text-theme"></i></a>
        </p>
        <p><i class="fa fa-home fa-fw w3-margin-right w3-text-theme"></i> {{ user.company }}</p>
        <!-- <p><i class="fa fa-birthday-cake fa-fw w3-margin-right w3-text-theme"></i> April 1, 1988</p> -->
        <p>
          <a *ngIf="!user.linkedInAccount" [href]="user.linkedInAccount" target="_blank" style="text-decoration: none;color: red;">
            <i class="fa fa-linkedin fa-fw w3-margin-right w3-text-theme"></i> Not set
          </a>
          <a *ngIf="user.linkedInAccount" [href]="user.linkedInAccount" target="_blank" style="text-decoration: none;color: navy;">
            <i class="fa fa-linkedin fa-fw w3-margin-right w3-text-theme"></i> {{ user.linkedInAccount }}
          </a>
        </p>
        <p>
          <a *ngIf="!user.gitAccount" target="_blank" style="text-decoration: none;color: red;">
            <i class="fa fa-github fa-fw w3-margin-right w3-text-theme"></i> {{ user.gitAccount }}
          </a>
          <a *ngIf="user.gitAccount" [href]="user.gitAccount" target="_blank" style="text-decoration: none;color: navy;">
            <i class="fa fa-github fa-fw w3-margin-right w3-text-theme"></i> {{ user.gitAccount }}
          </a>
        </p>
        <p>
          <a *ngIf="!user.twitter" target="_blank" style="text-decoration: none;color: red;">
            <i class="fa fa-twitter fa-fw w3-margin-right w3-text-theme"></i> Not set
          </a>
          <a *ngIf="user.twitter" [href]="user.twitter" target="_blank" style="text-decoration: none;color: navy;">
            <i class="fa fa-twitter fa-fw w3-margin-right w3-text-theme"></i> {{ user.twitter }}
          </a>
        </p>
      </div>
      <br>

      <br>
      <br>
      <br>
      <br>
    </div>
    <br>
  </div>

  <!-- Middle Column -->
  <div class="w3-col m7">
    <div class="w3-row-padding">
      <div class="w3-col m12">
        <div class="w3-card w3-round w3-white">
          <div class="w3-container w3-padding">
            <h4>My Courses</h4>
            <div class="w3-bar w3-white">
              <button class="w3-bar-item w3-button tablink" (click)="bookmarked=true;inprogress=false;certificates=false;"
                [ngClass]="{'w3-theme-d2': bookmarked, 'w3-white': inprogress || certificates}">Bookmarked</button>
              <button class="w3-bar-item w3-button tablink"
                [ngClass]="{'w3-theme-d2': inprogress, 'w3-white': bookmarked || certificates}"
                (click)="inprogress=true;bookmarked=false;certificates=false">In Progress</button>
              <button class="w3-bar-item w3-button tablink"
                [ngClass]="{'w3-theme-d2': certificates, 'w3-white': bookmarked || inprogress}"
                (click)="certificates=true;inprogress=false;bookmarked=false">My Certificates</button>
            </div>

            <div id="bookmarked" name="bookmarked" [ngClass]="{'w3-show': bookmarked, 'w3-hide': inprogress || certificates}"
              class="w3-container w3-border">
              <div class="w3-col s12">
                <app-dashboard-course [courses]="coursesBookmarked"></app-dashboard-course>
              </div>
            </div>

            <div id="inprogress" name="inprogress" [ngClass]="{'w3-show': inprogress, 'w3-hide': bookmarked || certificates}"
              class="w3-container w3-border city">
              <div class="w3-col s12">
                <app-dashboard-course [courses]="coursesInProgress"></app-dashboard-course>
              </div>
            </div>

            <div id="certificate" name="certificate" 
              [ngClass]="{'w3-show': certificates, 'w3-hide': bookmarked || inprogress}"
              class="w3-container w3-border city">
              <div class="w3-col s12">
                <app-user-certificates></app-user-certificates>
              </div>
            </div>


          </div>

          
        </div>
      </div>
    </div>
    <div class="w3-row-padding">
      <div class="w3-col m12">
        &nbsp;
      </div>
    </div>
    <div class="w3-row-padding">
      <div class="w3-col m12">
        <div class="w3-card w3-round w3-white">
          <div class="w3-container w3-padding">
            <div class="w3-row w3-margin-bottom">
              <div class="w3-col s12">
                <app-code-repos [user]="user.gitAccount.replace('https://github.com/', '')" [title]='userTitle'></app-code-repos>
              </div>
            </div>

          </div>
        </div>
      </div>
      </div>  
  </div>

  <!-- Right Column -->
  <div class="w3-col m2">
    <h5>Upcoming Events</h5>
    <div *ngFor="let event of events" class="w3-card w3-round w3-white w3-center w3-margin-bottom">
      <div  class="w3-container">
        <p><strong>{{ event.name }}</strong></p>
        <p>{{ event.date }}</p>
        <p><a class="w3-button w3-block w3-theme-l4" [routerLink]="[ '/EventInfo', event.id]">Info</a></p>
      </div>
    </div>    
    <br>
    <div class="w3-col  m6 s6 l6" [innerHtml]='tweet'>
      
    </div>
    
    <!-- End Right Column -->
  </div>

  <!-- End Grid -->
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/security/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loggedIn: boolean = true;
  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.userService.checkUserLoggedInOrNot().subscribe(val => {
        this.loggedIn = (val != null && val.token != "");
        console.log(val);
        if (val && val.token != "") {
          this.userService.isLoggedIn = true;
          //this.userService.isAdminSub.next(val.IsAdmin)
          if (!this.userService.redirectUrl) {
            this.userService.redirectUrl = '';
          }
          this.router.navigate([this.userService.redirectUrl]);
        }
      });
    }, 100)
  }

}

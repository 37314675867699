<div class="container">
    <div class="w3-row">
        <div class="w3-col w3-row-padding w3-twothird">
            <div class="w3-card">
                <header class="w3-theme-d2">
                    <div class="w3-container">
                        <h2>Welcome Tech People!</h2>
                    </div>
                </header>
                <div class="w3-container">
                    <div class="w3-row">
                        <div class="col-md-12" style="padding-bottom: 1px;">
                            <p>The <b>TechCodeLib</b> is an online library for video courses,
                                code samples, tech blogs, live sessions etc. related to software technologies. It provides an easy and
                                practical way of learning.
                                We can help you learn and improve your coding skills.
                                If you are just beginner or have just little programming knowledge, Junior, or Senior
                                Developer,
                                we can train you on some of the very popular and top-notch technologies.

                                <br>

                            </p>

                            <div>
                                <i class="fa fa-check-square-o" aria-hidden="true"></i> &nbsp;&nbsp;
                                <span><b>Step by Step learning - </b> With all our training courses we provide content for step by step learning. 
                                    We start with basic concepts first so that anyone can understand. 
                                </span>
                                <br>
                                <br>
                                <i class="fa fa-check-square-o" aria-hidden="true"></i> &nbsp;&nbsp;
                                <span><b>Free Courses - </b> All course code repositories, blogs, eBooks and all <span
                                    style="font-style: italic;text-decoration: underline;">Basic</span> and <span
                                    style="font-style: italic;text-decoration: underline;">Medium</span> level courses are always free of cost. 
                                </span>
                                <br>
                                <br>
                                <i class="fa fa-check-square-o" aria-hidden="true"></i> &nbsp;&nbsp;
                                <span><b>Pay as you go - </b> Every course is divided in Sections. You don't have to pay for the whole course or all sections. 
                                    Pay only what is needed. Every paid course has one section free.
                                </span>
                                <br>
                                <br>
                                <i class="fa fa-check-square-o" aria-hidden="true"></i> &nbsp;&nbsp;
                                <span><b>Softare Engineering Concepts - </b> We follow certain methodolgies which are
                                    widely used in software industry. For example how to
                                    maintain your code in <span
                                        style="font-style: italic;text-decoration: underline;">GitHub Repository</span>.
                                    How to track daily tasks in any project with <span
                                        style="font-style: italic;text-decoration: underline;">Agile methodolgy</span>.
                                    We will have trainings on some medium to
                                    large scaled projects where you will learn these Software Engineering conecpts along
                                    with technologies.
                                </span>
                                <br>
                                <br>
                                <i class="fa fa-check-square-o" aria-hidden="true"></i> &nbsp;&nbsp;
                                <span><b>Build a Profile - </b> With Tech-Code-Lib you not only learn technologies, we
                                    also let you create your own repository for the
                                    prjects you are creating and publish/share your profile with other mediums/resume.
                                    This helps recruiters know about your technical knowledge.
                                </span>
                                <br>
                                <br>
                                <i class="fa fa-check-square-o" aria-hidden="true"></i> &nbsp;&nbsp;
                                <span><b>Guided Trainings - </b> Every month we provide guided live sessions/trainings
                                    on certain technologies like Angular, React, ASP.Net Core, Java Script etc. You can
                                    enroll
                                    and get experience on some of the top notch technologies in market.
                                </span>
                                <br>
                                <br>
                            </div>

                            <input type="button" *ngIf="!loggedIn" (click)="navigateToNewUser()"
                                class="w3-btn w3-round-large w3-theme-d2" value="Register for free">&nbsp;&nbsp;<input
                                type="button" *ngIf="!loggedIn" (click)="navigateToSignIn()"
                                class="w3-btn w3-round-large w3-teal" value="Sign in">
                            <br>
                        </div>
                    </div>
                    <div class="w3-row">
                        <div class="w3-col">
                            &nbsp;
                        </div>
                    </div>
                    <div class="w3-row" *ngIf="false">
                     <div class="w3-col m3"><span style="color: brown;">See this video to understand how to use this portal?
                    <i class="fa fa-arrow-right"></i>
                    </span> </div>
                        <div class="w3-col m9">
                            <div>       
                                <iframe title="vimeo-player" src="https://player.vimeo.com/video/509455436" width="489" height="276"
                                frameborder="0" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w3-col w3-row-padding w3-third">
            <div class="w3-card">
                <header class="w3-teal">
                    <div class="w3-container">
                        <h2>Build your profile</h2>
                    </div>
                </header>
                <div class="w3-container">
                    <div class="w3-row">
                        <div class="w3-col m12 l12">
                            <div style="overflow-y: auto; max-height:720px;">
                                <div class="w3-row" *ngFor="let careerPath of careerPaths">
                                    <div class="w3-col w3-container w3-padding">
                                        <a [routerLink]="['/CareerPath', careerPath.id]" 
                                            class="w3-button w3-round-large w3-teal">{{careerPath.name}}
                                        </a>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="false" class="w3-row">
        <div class="w3-col w3-row-padding w3-third w3-margin-top w3-center">
            <div>    
                <span>How to use this portal?</span>                   
            </div>
        </div>
        <div  class="w3-col w3-row-padding w3-third w3-margin-top w3-center">
            <div>    
                <span>How to use this portal?</span>    
                <br />        
                <iframe title="vimeo-player" src="https://player.vimeo.com/video/509455436" width="489" height="276" 
                frameborder="0" allowfullscreen></iframe>
            </div>
        </div>
        <!-- <div class="w3-col w3-row-padding w3-third w3-margin-top w3-center">
            <div>
                <iframe src="https://player.vimeo.com/video/491370623" width="387" height="290" frameborder="0"
                    allow="autoplay; fullscreen" allowfullscreen>
                </iframe>
            </div>
        </div>
        <div class="w3-col w3-row-padding w3-third w3-margin-top w3-right-align">
            <div>
                <iframe src="https://player.vimeo.com/video/491370623" width="387" height="290" frameborder="0"
                    allow="autoplay; fullscreen" allowfullscreen>
                </iframe>
            </div>
        </div> -->
    </div>    
</div>
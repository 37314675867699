import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CodeRepo } from '../models/codeRepo';

@Injectable({
    providedIn: 'root'
})
export class CodeReposService {
    constructor(private http: HttpClient){}
    getPublicRepos(user: string): Observable<CodeRepo[]>{
      let gitUrl = `https://api.github.com/users/${user}/repos?client_id=6acb8a558d042cee8bd6&scope=user,public_repo&client_secret=911a0d30a752afcd62c5c072e0c0edf730d95654`
      return this.http.get<CodeRepo[]>(gitUrl)
          .pipe(
              catchError(this.handleError)
          );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        return throwError(
          'Something bad happened; please try again later.');
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardDisplayComponent } from './components/dashboard-display/dashboard-display.component';
import { CoursesModule } from '../courses/courses.module';
import { CodeReposModule } from '../code-repos/code-repos.module';
import { RouterModule } from '@angular/router';
import { CourseCertificatesModule } from '../certificates/course-certificates.module';



@NgModule({
  declarations: [DashboardDisplayComponent],
  imports: [
    CommonModule, CoursesModule, CodeReposModule, RouterModule, CourseCertificatesModule
  ],
  exports:[
    DashboardDisplayComponent
  ]
})
export class DashboardModule { }

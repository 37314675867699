import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogsComponent } from './components/blogs/blogs.component';

@NgModule({
    declarations: [BlogsComponent],
    imports: [ CommonModule ],
    exports: [],
    providers: [],
})
export class BlogsModule {}

<div class="w3-container w3-show">
    <div class="w3-row">
        <div class="w3-col s1 m1 l1">

        </div>
        <form novalidate #frmQuiz="ngForm" 
        (ngSubmit)="Submit()">
            <div class="w3-col s11 m11 l11">  
                <div *ngIf="submittedReviewPending">
                    <p>Your response has been submitted for review.</p>
                </div>                  
                <div id="divQuizQuestion" *ngFor="let quizQuestion of quizQuestions; let i = index" 
                    #divQuizQuestion name="divQuizQuestion"                         
                    class="w3-container w3-show w3-padding-16">
                    <p style="color: darkblue;">{{ quizQuestion.question }}</p>                    
                    <textarea class="w3-input w3-border" name="userResponse{{i}}"  
                        [(ngModel)]="quizQuestion.userResponse" 
                        [disabled]="quizQuestion.submittedByUser"
                        #userResponse="ngModel"
                        placeholder="Enter your response(Max 250 Charaters)" maxlength="250"></textarea> 
                        <br>
                    
                    <p style="color: brown;">
                        <i class="fa fa-check" *ngIf="quizQuestion.isCorrect" aria-hidden="true"></i>
                        <i class="fa fa-bug" *ngIf="!quizQuestion.isCorrect && quizQuestion.reviewerRemarks" aria-hidden="true"></i>
                        {{ quizQuestion.reviewerRemarks }}</p>                         
                    
                </div>
                <div *ngIf="!quizQuestions[0].submittedByUser" class="w3-container w3-show w3-padding-16">
                    <input type="submit" class="w3-btn w3-theme-d2" 
                    value="Submit">&nbsp;&nbsp;
                    <input type="submit" (click)="toggleSubmitByUser()" class="w3-btn w3-theme-d2" 
                    value="Save">
                </div>                
            </div>           
        </form>
    </div>
    <div>
        <hr>
    </div>
</div>
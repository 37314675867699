<div>
  <div class="w3-top">
    <div class="w3-bar w3-theme-d2 w3-left-align w3-large">
      <a class="w3-bar-item w3-button w3-hide-medium w3-hide-large w3-right w3-padding-large w3-hover-white w3-large w3-theme-d2"
        href="javascript:void(0);" (click)="hideNav = !hideNav"><i class="fa fa-bars"></i></a>
      <a href="#" class="w3-bar-item w3-button w3-padding-large w3-theme-d4">
        <i class="fa fa-home w3-margin-right"></i>
      </a>


      <a [routerLink]="[ '/MyProfile', userId]" *ngIf="loggedIn"routerLinkActive="w3-white"
        class="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white" title="My Profile">
        <i class="fa fa-bar-chart" >
          <span style="font-family: Verdana;font-size: smaller;">&nbsp;Dashboard</span>
        </i>
      </a>

      <a *ngIf="loggedIn" [routerLink]="[ '/Browse']" routerLinkActive="w3-white"
        class="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white" title="Browser All Courses">
        <i class="fa fa-laptop">
          <span style="font-family: Verdana;font-size: smaller;">&nbsp;Courses</span>
        </i>
      </a>


      <a href="#" *ngIf="loggedIn" [routerLink]="[ '/Message']" routerLinkActive="w3-white"
        class="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white" title="Message">
        <i class="fa fa-envelope">
          <span style="font-family: Verdana;font-size: smaller;">&nbsp;Contact us</span>
        </i>
      </a>

      <a href="#" *ngIf="loggedIn" [routerLink]="[ '/CodeRepositories']" routerLinkActive="w3-white"
        class="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white" title="Code Repositories">
        <i class="fa fa-code">
          <span style="font-family: Verdana;font-size: smaller;">&nbsp;Repositories</span>
        </i>
      </a>

      <a href="#" *ngIf="loggedIn" [routerLink]="[ '/TodaysCodeSample']" routerLinkActive="w3-white"
        class="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white" title="Daily Code Examples">
        <i class="fa fa-file-code-o">
          <span style="font-family: Verdana;font-size: smaller;">&nbsp;Code examples</span>
        </i>
      </a>

      <a href="#" *ngIf="loggedIn" [routerLink]="[ '/Blogs']" routerLinkActive="w3-white"
        class="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white" title="Tech Blogs">        
        <i class="fa fa-newspaper-o">
          <span style="font-family: Verdana;font-size: smaller;">&nbsp;Blogs</span>
        </i>
      </a>

      <div *ngIf="loggedIn" class="w3-dropdown-hover w3-hide-small">
        <button class="w3-button w3-padding-large" title="Notifications"><i class="fa fa-bell"></i><span
            class="w3-badge w3-right w3-small w3-green">{{ userNotifications.length }}</span></button>
        <div class="w3-dropdown-content w3-card-4 w3-bar-block" style="width:300px">
          <a *ngFor="let item of userNotifications" [routerLink]="item.redirectUrl" class="w3-bar-item w3-button"
            style="font-size: 12px;">
            {{ item.desc }}
          </a>
        </div>
      </div>
      <div>
        <a href="#" [routerLink]="[ '/Updates']" routerLinkActive="w3-white"
          class="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white">
          <span style="font-family: Verdana;font-size: smaller;">Updates</span>
        </a>
      </div>
      <div>
        <a href="#" [routerLink]="[ '/FAQ']" routerLinkActive="w3-white"
          class="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white">
          <span style="font-family: Verdana;font-size: smaller;">FAQ</span></a>
      </div>
      <div>
        <a href="#" [routerLink]="[ '/AllCourses']" routerLinkActive="w3-white"
          class="w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white">
          <span style="font-family: Verdana;font-size: smaller;">All Courses</span>
        </a>
      </div>
      <a *ngIf="loggedIn" href="#"
        class="w3-bar-item w3-button w3-hide-small w3-right w3-padding-large w3-hover-white" (click)="Signout()" title="Sign out">
            <i class="fa fa-sign-out" aria-hidden="true">
              <span style="font-family: Verdana;font-size: smaller;">&nbsp;Sign out</span>
            </i>     
      </a>


      

      

      

    </div>
  </div>

  <!-- Navbar on small screens -->

  <div id="navDemo" [ngClass]="{'w3-hide': hideNav, 'w3-show': !hideNav}"
    class="w3-bar-block w3-theme-d2 w3-hide w3-hide-large w3-hide-medium w3-large">
    <a href="#" class="w3-bar-item w3-button w3-padding-large">&nbsp;</a>
    <!-- <a [routerLink]="[ '/MyProfile', userId]" *ngIf="loggedIn" 
      routerLinkActive="w3-white" 
      class="w3-bar-item w3-button w3-padding-large"
      title="My Profile"><i class="fa fa-user"></i></a> -->
    <a [routerLink]="[ '/MyProfile', userId]" *ngIf="loggedIn" routerLinkActive="w3-white" (click)="hideNav = !hideNav"
      class="w3-bar-item w3-button w3-padding-large"><i class="fa fa-user">
        <span style="font-family: Verdana;font-size: smaller;">&nbsp;Dashboard</span>
      </i></a>

    <a *ngIf="loggedIn" [routerLink]="[ '/Browse']" routerLinkActive="w3-white"
      class="w3-bar-item w3-button w3-padding-large" (click)="hideNav = !hideNav" title="Browser All Courses">
      <i class="fa fa-laptop">
        <span style="font-family: Verdana;font-size: smaller;">&nbsp;Courses</span>
      </i>
    </a>

    <a href="#" *ngIf="loggedIn" [routerLink]="[ '/CodeRepositories']" routerLinkActive="w3-white" (click)="hideNav = !hideNav"
        class="w3-bar-item w3-button w3-padding-large" title="Code Repositories">
        <i class="fa fa-code">
          <span style="font-family: Verdana;font-size: smaller;">&nbsp;Repositories</span>
        </i>
      </a>
    <a href="#" *ngIf="loggedIn" [routerLink]="[ '/TodaysCodeSample']" routerLinkActive="w3-white" (click)="hideNav = !hideNav"
      class="w3-bar-item w3-button w3-padding-large" title="Daily code examples">
      <i class="fa fa-code">
        <span style="font-family: Verdana;font-size: smaller;">&nbsp;Code examples</span>
      </i>
    </a>
    <a href="#" *ngIf="loggedIn" [routerLink]="[ '/Blogs']" routerLinkActive="w3-white" (click)="hideNav = !hideNav"
        class="w3-bar-item w3-button w3-padding-large" title="Code Repositories">
        <i class="fa fa-code">
          <span style="font-family: Verdana;font-size: smaller;">&nbsp;Blogs</span>
        </i>
      </a>
      <div *ngIf="loggedIn"  class="w3-dropdown-hover">
        <button class="w3-button w3-padding-large" (click)="hideNav = !hideNav" title="Notifications"><i class="fa fa-bell"></i><span
            class="w3-badge w3-right w3-small w3-green">{{ userNotifications.length }}</span></button>        
      </div>
      <div>
        <a href="#" [routerLink]="[ '/Updates']" routerLinkActive="w3-white" (click)="hideNav = !hideNav"
          class="w3-bar-item w3-button w3-padding-large w3-hover-white">Updates</a>
      </div>
      <div>
        <a href="#" [routerLink]="[ '/FAQ']" routerLinkActive="w3-white" (click)="hideNav = !hideNav"
          class="w3-bar-item w3-button w3-padding-large w3-hover-white">FAQ</a>
      </div>
      <div>
        <a href="#" [routerLink]="[ '/AllCourses']" routerLinkActive="w3-white" (click)="hideNav = !hideNav"
          class="w3-bar-item w3-button w3-padding-large w3-hover-white">All Courses</a>
      </div>
      
    <a [routerLink]="[ '/Home']" *ngIf="!loggedIn" (click)="hideNav = !hideNav" class="w3-bar-item w3-button w3-padding-large">Sign In
    </a>
    <a *ngIf="loggedIn" href="#" (click)="Signout()"
        class="w3-bar-item w3-button w3-right w3-padding-large w3-hover-white" title="Sign out">
        <i class="fa fa-sign-out" aria-hidden="true">
          <span style="font-family: Verdana;font-size: smaller;">&nbsp;Sign out</span>
        </i>
      </a>
  </div>
</div>
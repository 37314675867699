import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodeReposComponent } from './components/code-repos.component';

@NgModule({
    declarations: [CodeReposComponent],
    imports: [ CommonModule ],
    exports: [CodeReposComponent],
    providers: [],
})
export class CodeReposModule {}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { appEvent } from '../models/appEvents';
import { userNotification } from '../models/userNotification';

@Injectable({
    providedIn: 'root'
})
export class UserNotificationService {
    allEvents: appEvent[]=[{
            id:1,
            name:'Live Session on Java Script',
            date: 'March 25, 2021 10 AM',
            detail: 'Live session on important topics of Java Script.',
            isActive: true
        },
        {
            id:1,
            name:'Live Session on Entity Framework',
            date: 'Feb 25, 2021 10 AM',
            detail: 'Live session on creating DAL in Entity Framework',
            isActive: true
        }
    ];

    getAllUserNotifications():Observable<userNotification[]>{
        let notifications: userNotification[] = [];
        notifications.push({
            id:1,
            name:'New course in Java Script',
            desc: 'A new course has been added for Java Script basics',
            redirectUrl: 'Courses/1'
        });
        notifications.push({
            id:2,
            name:'New course for MS.Net/C#',
            desc: 'A new course has been added for MS.Net/C# basics',
            redirectUrl: 'Courses/2'
        });        
        return of(notifications);
    }

    getAllEvents():Observable<appEvent[]>{
        return of(this.allEvents);
    }

    getEventInfo(id: number):Observable<appEvent>{
        return of(this.allEvents.filter(x => x.id === id)[0]);
    }

    getPublishedTweet():Observable<string>{
        return of(`<blockquote class="twitter-tweet">
        <p lang="en" dir="ltr">
          <a href="https://t.co/6x7Fc9jbjI">https://t.co/6x7Fc9jbjI</a> portal is up today with first Basic Level JavaScript Course.
        </p>&mdash; tech-code-lib (@code_lib) <a href="https://twitter.com/code_lib/status/1353532746061262849?ref_src=twsrc%5Etfw">January 25, 2021</a>
      </blockquote>`);
    }
}
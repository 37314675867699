import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { quizQuestionResponse } from '../../models/quizQuestionResponse';
import { CoursesService } from '../../services/courses.service';

@Component({
    selector: 'app-quiz',
    templateUrl: './quiz.component.html'    
})
export class QuizComponent implements OnInit {
    @Input('quizQuestions')
    quizQuestions: quizQuestionResponse[];
    submitByUser: boolean = true;
    submittedReviewPending: boolean = false;
    constructor(private courseService: CoursesService, private router: Router) { }

    ngOnInit(): void { 
        for(let i=0; i < this.quizQuestions.length; i++)
        {
            let x = this.quizQuestions[i];
            if(x.submittedByUser && !x.reviewerRemarks)
            {
                this.submittedReviewPending = true;
            }
        }
    }

    toggleSubmitByUser(){
        this.submitByUser = false;
    }
    Submit(){
        console.log(this.quizQuestions);
        this.quizQuestions.forEach(x => {
            x.submittedByUser = this.submitByUser;
        });
        
        if(window.confirm("Are you sure?")){
            this.courseService.postQuizResponse(this.quizQuestions).subscribe(data => {
                this.quizQuestions = data;
                this.submitByUser = true;
                this.submittedReviewPending = true;
            });            
        }

        
    }
}

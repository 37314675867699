<h2>Daily Code Sample</h2>
<table class="w3-table">
    <thead>
        <tr class="w3-light-grey">
            <th>Title</th>
            <th>Subject</th>
            <th>View</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="!liveCodeSamples">
            <td colspan="3" style="color: red;">No code sample found.</td>
        </tr>
        <tr *ngFor="let codeSample of liveCodeSamples">
            <td>{{ codeSample.detail }}</td>
            <td>{{ codeSample.subject }}</td>
            <td>
                <a [href]="codeSample.webLink" target="_blank"
                    class="w3-bar-item w3-button w3-padding-small w3-hover-white" title="View">
                    <i class="fa fa-external-link-square" style="font-size:25px"></i>
                </a>
            </td>
        </tr>
        <tr>
            <td colspan="3">&nbsp;</td>
        </tr>
    </tbody>
</table>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { appEvent } from '../models/appEvents';
import { UserNotificationService } from '../services/user-notification.service';

@Component({
    selector: 'app-event-info',
    templateUrl: './event-info.component.html',    
})
export class AppEventComponent implements OnInit {

    appEvent: appEvent;
    constructor(private route: ActivatedRoute, private userNotificationService: UserNotificationService ) { }

    ngOnInit(): void { 

        this.route.params.pipe(map(x => x.id)).subscribe((id) => {
            this.userNotificationService.getEventInfo(+id).subscribe(event => this.appEvent = event);
        });
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { AppUserService } from 'src/app/dashboard/services/app-user.service';
import { UserService } from 'src/app/security/services/user.service';
import { course } from '../../models/course';
import { CoursesService } from '../../services/courses.service';

@Component({
    selector: 'app-course',
    templateUrl: './course.component.html'
    
})
export class CourseComponent implements OnInit {
    @Input('course')
    course: course;
    constructor(private service: AppUserService, private userService: UserService) { 
        console.log('CourseComponent constructor');
    }

    ngOnInit(): void {       
    }

    markBookmark(){
        this.course.bookmarked = !this.course.bookmarked;
        let userId = this.userService.getLocalStorage("AuthToken").userId;
        let appUserCourse = 
        {
            appUserId: userId,
            courseId: this.course.id,
            inProgress: this.course.inProgress,
            bookmarked: this.course.bookmarked,
            finished: this.course.finished
        };
        this.service.updateAppUserCourse(appUserCourse).subscribe(data => console.log(data));
    }
}


import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { assignmentResponse } from '../../models/assignmentResponse';

import { CoursesService } from '../../services/courses.service';

@Component({
    selector: 'app-assignment',
    templateUrl: './assignment.component.html'    
})
export class AssignmentComponent implements OnInit {
    @Input('assignmentTasks')
    assignmentTasks: assignmentResponse[];
    submitByUser: boolean = true;
    submittedReviewPending: boolean = false;
    constructor(private courseService: CoursesService, private router: Router) { }

    ngOnInit(): void { 
        for(let i=0; i < this.assignmentTasks.length; i++)
        {
            let x = this.assignmentTasks[i];
            if(x.submittedByUser && !x.reviewerRemarks)
            {
                this.submittedReviewPending = true;
            }
        }
    }

    toggleSubmitByUser(){
        this.submitByUser = false;
    }
    Submit(){
        console.log(this.assignmentTasks);
        this.assignmentTasks.forEach(x => {
            x.submittedByUser = this.submitByUser;
        });
        
        if(window.confirm("Are you sure?")){
            this.courseService.postAssignmentResponse(this.assignmentTasks).subscribe(data => {
                this.assignmentTasks = data;
                this.submitByUser = true;
                this.submittedReviewPending = true;
            });            
        }

        
    }
}

import { environment } from 'src/environments/environment';

export class AppSettings{  
    techCodeLibAPIBaseUrl: string;
    getToken: string = "/api/AppUsers/Token";
    getTokenWithGit: string = "/api/AppUsers/GitToken";
    courses: string = "/api/Courses";
    dailyCodeSamples: string = "/api/LiveCodeSamples";
    blogs: string = "/api/Blogs";
    courseQuizs: string = "/api/Quizs/ByCourseUser";
    courseAssignments: string = "/api/Assignments/ByCourseUser";
    courseQuizResponse: string = "/api/QuizResponses/AddUpdateQuizResponse";
    appUserCourses: string = "/api/AppUserCourses";
    getAppUserCourseDetail: string = "/api/AppUserCourses/GetAppUserCourseDetail";
    addUpdateUserCourse:string = "/api/AppUserCourses/AddUpdateUserCourse";
    secretQuestions: string = "/api/SecretQuestions";
    documents: string = "/api/Documents";
    addUsers: string = "/api/AppUsers/AddUser";
    updateUser: string = "/api/AppUsers/UpdateUser";
    users: string = "/api/AppUsers";
    getUserByEmail: string = "/api/AppUsers/email";
    resetPassword: string = "/api/AppUsers/ResetPassword";
    getUserCertificates: string = "/api/UserCertificates/";
    enableFreeTrial: string = "/api/Sections/EnableFreeTrial";
    isSectionEnableForUser: string = "/api/Sections/IsSectionEnableForUser";
    getAppUserPaymentToMake: string = "/api/AppUserPayments/GetUsersCourseSectionPayment";
    getSignature: string = "/api/AppUserPayments/GetSignature";
    paymentRedirectUrl: string = "/api/AppUserPayments/PostPaymentAPIResponse";
    paymentNotifyUrl: string = "/api/AppUserPayments/PostPaymentNotifyAPIResponse";
    getAllSocialMediaPosts: string = "/api/SocialMediaPosts";

    constructor(){
        this.techCodeLibAPIBaseUrl = environment.techCodeLibAPIBaseUrl;

    }
}
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Observable, of, Subject, throwError } from 'rxjs';
import { LoggedInAppUser } from '../models/loggedInAppUser';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { AppSettings } from 'src/app/shared/models/appsettings';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { catchError, delay, tap } from 'rxjs/operators';
import { AppUser } from '../models/appUser';
import { SecretQuestion } from '../models/secretQuestion';
import { NewAppUser } from '../models/newAppUser';
import { resetPassword } from '../models/resetPassword';

@Injectable({ providedIn: 'root' })
export class UserService {
    private appSettings: AppSettings;
    public isLoggedIn: boolean;
    public isLoggedInSub: Subject<boolean> = new Subject<boolean>();
    public loggedInAppUser: LoggedInAppUser;
    redirectUrl: string;

    constructor(private firestore: AngularFirestore, private afStorage: AngularFireStorage,
        private appSettingsService: AppSettingsService,
        private http: HttpClient) { 
            this.appSettingsService.getSettings().subscribe(data => {
                //console.log(data);
                this.appSettings = data;});
    }

    getSecretQuestions(): Observable<SecretQuestion[]>{
        let secretQuestions: SecretQuestion[]=[];
        // secretQuestions.push({id:1, question:"Your birth place."} as SecretQuestion);
        // secretQuestions.push({id:2, question:"Your favourite color."} as SecretQuestion);
        // secretQuestions.push({id:3, question:"Name of the place where your parents got married."} as SecretQuestion);
        // secretQuestions.push({id:4, question:"First Name of your grand father."} as SecretQuestion);
        // return of(secretQuestions);

        var secretQuestionsurl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.secretQuestions;
        //console.log(secretQuestionsurl);
        return this.http.get<SecretQuestion[]>(secretQuestionsurl)
            .pipe(
                catchError(this.handleError));
        
    }


    gitLogin(user: NewAppUser):Observable<LoggedInAppUser>{
        var appSecurityTokenUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.getTokenWithGit;
        //console.log(appSecurityTokenUrl);
        if (user) {
          return this.http.post<LoggedInAppUser>(appSecurityTokenUrl, user).pipe(
            catchError(this.handleError),
            delay(500),
            tap(val => {
              if (val && val.token) {
                this.isLoggedIn = true;
                
                //this.isAdminSub.next(val.isAdmin)
                this.loggedInAppUser = val;               
                this.setLocalStorage();
                this.isLoggedInSub.next(true);
              }
            })
          );
        }
    }
    login(user: User): Observable<LoggedInAppUser>{
        //console.log(user);
        
        
        var appSecurityTokenUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.getToken;
        console.log(appSecurityTokenUrl);
        if (user) {
          return this.http.post<LoggedInAppUser>(appSecurityTokenUrl, user).pipe(
            catchError(this.handleError),
            delay(500),
            tap(val => {
              if (val && val.token) {
                this.isLoggedIn = true;
                
                //this.isAdminSub.next(val.isAdmin)
                this.loggedInAppUser = val;               
                this.setLocalStorage();
                this.isLoggedInSub.next(true);
              }
            })
          );
        }

        var appUserRef = this.firestore.collection('AppUsers');
        appUserRef.ref.where('email', '==', user.username).where('password', '==', user.password).get().then(data =>{
            if(data.docs.length === 0){
                console.log('No user found');
            }
            data.docs.forEach(x => {
                if (x.exists) {
                    console.log(x.data());
                }                
            })
        } );

    }

    checkUserLoggedInOrNot(): Observable<LoggedInAppUser> {
        let user: LoggedInAppUser = this.getLocalStorage('AuthToken');
        console.log(user)
        if (user != null && user.email != '') {
            console.log('Logged in Already')
            this.isLoggedInSub.next(true);
          return of(user);
        }
        else
        {
            console.log('Not Logged in Already')
            this.isLoggedInSub.next(false);
            return of(null);
        }
        
      }

    setLocalStorage() {
        localStorage.removeItem("AuthToken");
        localStorage.setItem("AuthToken", JSON.stringify(this.loggedInAppUser));
    }

    getLocalStorage(k: string): LoggedInAppUser {
        var val = localStorage.getItem(k);
        let loggedInAppUserInfo: LoggedInAppUser = (val) ? JSON.parse(val) : { token: '', isAdmin: false, email: '' };
        return loggedInAppUserInfo;
    }
    private handleError(error: HttpErrorResponse) {
        //console.log(error.error);
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        let errorMsg =  (error.error != null)? error.error.msg: '';
        if(!errorMsg)
            errorMsg = 'Something bad happened; please try again later.'
        return throwError(errorMsg);
    }

    upload(file: any, email: string): Promise<any>{
        var appUserRef = this.firestore.collection('AppUsers');
        var promise = new Promise<any>((resolve, reject) => {
        appUserRef.ref.where('email', '==', email).get().then(data =>{            
            data.docs.forEach(x => {
                if (x.exists) {
                    //console.log(x.id);
                    this.afStorage.upload('/upload/profile_' + x.id + '.jpg', file);                     
                    resolve(x.id);
                }                
            })
        } ).
        catch(
            () => reject('Error OCcured')
        );
        });
        return promise;
        

        
    }

    FetchSecretQuestions(email: string){
        var getUserByEmail = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.getUserByEmail + `?email=${email}`;
        return this.http.get<resetPassword>(getUserByEmail).pipe(catchError(this.handleError));
    }

    Signout(){ 
        console.log('Signout called')
        localStorage.removeItem("AuthToken");       
        this.isLoggedIn = false;
        this.isLoggedInSub.next(false);        
        this.redirectUrl = '';
        //localStorage.removeItem("AuthToken");
    }

    ResetPassword(resetPawd: resetPassword):Observable<string>{
        //console.log(resetPassword);
        var resetPwdUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.resetPassword;
        return this.http.post<string>(resetPwdUrl, resetPawd).pipe(catchError(this.handleError));
    }

    saveUser(newAppUser: NewAppUser): Observable<number> {    
        // let newUserId=1;
        // return of(newUserId);
        let addUpdateAppUserUrl: string='';
        if(newAppUser.id == 0)
        {
            addUpdateAppUserUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.addUsers;            
        }
        else
        {
            addUpdateAppUserUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.updateUser; 
        }
         
        return this.http.post<number>(addUpdateAppUserUrl,newAppUser).pipe(catchError(this.handleError));

        /*
        
        var appUserRef = this.firestore.collection('AppUsers');
        return appUserRef.ref.where('email', '==', appUser.email).get().then(data =>{
            if(data.docs.length === 0){
                this.firestore.collection('AppUsers').add(appUser);
            }else{
                data.docs.forEach(x => {
                    if (x.exists) {
                        console.log(x.id);
                    }                
                })
            }
        } );
        */
    }

    uploadFormData(formData){
        var postFormData = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.documents;
        return this.http.post(postFormData, formData, {reportProgress: true, observe: 'events'});
          
    }

}
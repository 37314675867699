
<div class="w3-row">
    <div class="w3-col m5 s5 l5">
        <a class="twitter-timeline" data-height="600" 
        href="https://twitter.com/code_lib?ref_src=twsrc%5Etfw">Tweets by code_lib</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    </div> 
    <div class="w3-col m1 s1 l1">
        &nbsp;
    </div>
    <div class="w3-col m5 s5 l5">
        <p style="color:navy">            
            Please see below calendar for all our upcoming events.
        </p>
        <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23009688&amp;ctz=America%2FNew_York&amp;src=dGVjaGNvZGVsaWJAZ21haWwuY29t&amp;color=%23039BE5&amp;showTitle=0&amp;showCalendars=0&amp;showPrint=0&amp;showNav=1&amp;showTabs=0&amp;title=All%20Events" 
        style="border:solid 1px #777" width="750" height="550" frameborder="0" scrolling="no"></iframe>
    </div> 
</div>

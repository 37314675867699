<div class="w3-card">
    <header class="w3-theme-d2">
        <div class="w3-container">
            <h2>New User Registration</h2>
        </div>
    </header>
    <form novalidate class="w3-container" #frmLogin="ngForm" 
        (ngSubmit)="Submit()">
        <div class="w3-row">
            <div class="w3-col l6 m6 s12">
                <div class="w3-row w3-section">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>User Id/Email</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <input class="w3-input w3-border" [ngClass]="{'w3-border-red': userName.invalid}" name="email"
                            type="email"
                            pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                            [(ngModel)]="user.email" required #userName="ngModel" placeholder="Email id">
                        <!-- <div *ngIf="userName.invalid" style="color: red;">Enter valid email id</div> -->
                    </div>
                </div>
        
                <div class="w3-row w3-section">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>Password</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <input class="w3-input w3-border" [ngClass]="{'w3-border-red': password.invalid}" name="password"
                            type="text" minlength="8" [(ngModel)]="user.password" required #password="ngModel" type="password"
                            placeholder="Password(min 8 characters)">                        
                        <!-- <div *ngIf="password.invalid" style="color: red;">Enter valid password</div> -->
                    </div>
                </div>
        
                <div class="w3-row w3-section">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>First Name</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <input class="w3-input w3-border" [ngClass]="{'w3-border-red': fname.invalid}" name="fname" type="text"                    
                            [(ngModel)]="user.fname" required #fname="ngModel" placeholder="First Name">
                        <!-- <div *ngIf="fname.invalid" style="color: red;">Enter First Name</div> -->
                    </div>
                </div>
                <div class="w3-row w3-section">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>Last Name</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <input class="w3-input w3-border" [ngClass]="{'w3-border-red': lname.invalid}" name="lname" type="text"                    
                            [(ngModel)]="user.lname" required #lname="ngModel" placeholder="Last Name">
                        <!-- <div *ngIf="lname.invalid" style="color: red;">Enter Last Name</div> -->
                    </div>
                </div>
                <div class="w3-row w3-section">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>Bio</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <textarea class="w3-input w3-border" rows="4"  name="bio" maxlength="250" [(ngModel)]="user.bio" #bio="ngModel"
                            placeholder="Bio(Max 250 characters)"></textarea>                            
                            <span  class="text-secondary" style="font-size: smaller;">{{ 250 - user.bio.length}} characters remaining.</span>
                    </div>
                </div>
                <div class="w3-row w3-section">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>Secret Question 1</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <select id="secQ1Id" name="secQ1Id" #secQ1Id [ngClass]="{'w3-border-red': secQ1Id.invalid}" required [(ngModel)]="user.secQ1Id" 
                        class="w3-input w3-border">
                            <option *ngFor="let item of secretQuestions" [value]="item.id">{{ item.question }}</option>
                        </select>
                        <br>
                        <input class="w3-input w3-border" [ngClass]="{'w3-border-red': seQ1Ans.invalid}" required name="seQ1Ans" [(ngModel)]="user.seQ1Ans" #seQ1Ans="ngModel"
                                placeholder="Secret Question 1 Answer">
                        <!-- <div *ngIf="seQ1Ans.invalid" style="color: red;">Enter Answer for Secret Question 1</div> -->
                    </div>
                </div>  
                             
            </div>
            <div class="w3-col s6 m6 s12">                
                <div class="w3-row w3-section">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>Secret Question 2</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <select id="secQ2Id" name="secQ2Id" #secQ2Id [ngClass]="{'w3-border-red': secQ2Id.invalid || secQ2Id.value == secQ1Id.value}" required 
                        [(ngModel)]="user.secQ2Id" class="w3-input w3-border">
                            <option *ngFor="let item of secretQuestions" [value]="item.id">{{ item.question }}</option>
                        </select>
                        <br>
                        <input class="w3-input w3-border" name="seQ2Ans" [(ngModel)]="user.seQ2Ans"
                        [ngClass]="{'w3-border-red': seQ2Ans.invalid}" required  #seQ2Ans="ngModel"
                                placeholder="Secret Question 2 Answer">
                        <!-- <div *ngIf="seQ2Ans.invalid" style="color: red;">Enter Answer for Secret Question 2</div> -->
                    </div>
                </div>
                <div class="w3-row w3-section">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>Organization</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <input class="w3-input w3-border" name="company" [(ngModel)]="user.company" #company="ngModel"
                            placeholder="Name of Company/School/Organization">
        
                    </div>
                </div>
        
                <div class="w3-row w3-section">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>GitHub Account</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <input class="w3-input w3-border" name="gitAccount" [(ngModel)]="user.gitAccount"
                            #gitAccount="ngModel" placeholder="GitHub Account">
        
                    </div>
                </div>
        
                <div class="w3-row w3-section">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>LinkedIn Account</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <input class="w3-input w3-border" name="linkedInAccount" [(ngModel)]="user.linkedInAccount"
                            #linkedInAccount="ngModel" placeholder="LinkedIn Account">
        
                    </div>
                </div>
        
                <div class="w3-row w3-section">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>Twitter Account</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <input class="w3-input w3-border" name="twitter" [(ngModel)]="user.twitter"                     
                        #twitter="ngModel" placeholder="Twitter Account">
        
                    </div>
                </div>
        
                
                <div class="w3-row w3-section" *ngIf="ChangeImage">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>Image</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" accept="image/*" style="display:none;">
                        <button type="button" class="btn btn-success" (click)="file.click()">Upload File</button>
                    </div>
                    <div>
                        <span class="upload" *ngIf="progress > 0">
                          {{progress}}%
                        </span>
                        <span class="upload" *ngIf="message">
                          {{message}}
                        </span>
                      </div>
                </div>
                
                <div *ngIf="user.avatar_url && !ChangeImage" class="w3-row w3-section">
                    <div class="w3-col l3 m3 s12">
                        <label class="w3-text-teal"><b>Profile Picture</b></label></div>
                    <div class="w3-col l7 m7 s12">
                        <a href="#" (click)="CloseImage()"><i class="fa fa-times"></i></a> 
                        <p class="w3-center"><img  [src]="user.avatar_url" class="w3-circle" style="height:106px;width:106px"
                            alt="Avatar">
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="w3-row">
            <div class="w3-col l12 m12 s12">
                <div class="w3-row w3-section">
                    <div>
                        <input type="submit" class="w3-btn w3-theme-d2" [disabled]="frmLogin.invalid" value="Save">
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div>  
        <!-- <div>
            <pre>{{ frmLogin.value | json }}</pre>
        </div> -->
    </form>
    
</div>

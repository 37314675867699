import { Component, OnInit } from '@angular/core';
import { blog } from '../../models/blog';
import { BlogService } from '../../services/blog.service';

@Component({
    selector: 'app-blogs',
    templateUrl: './blogs.component.html'
})
export class BlogsComponent implements OnInit {
    blogs: blog[];
    constructor(private blogService: BlogService) { }

    ngOnInit(): void { 
        this.blogService.getAllBlogs().subscribe(data => this.blogs = data);
    }
}

<div class="w3-card">
    <header class="w3-theme-d2">
        <div class="w3-container">
            <h2>Course-Section Payment</h2>
        </div>
    </header>
    <div *ngIf="paymentSuccess" class="w3-container">
        <span>You payment has been submitted.</span>
        <br>
        <span><b>ReferenceId: </b>{{ referenceId }}</span>
        <br>
        <a [routerLink]="[ '/Courses', courseId]">Back to Course</a>

    </div>

    <form *ngIf="payment" ngNoForm novalidate target="_blank" class="w3-container w3-half">
        

        <div class="w3-row w3-section">
            <div class="w3-col w3-third">
                <label class="w3-text-teal"><b>Email</b></label>
            </div>
            <div class="w3-col w3-twothird">
                <input [disabled]="makePayment" class="w3-input w3-border" [ngClass]="{'w3-border-red': customerEmail.invalid}" name="customerEmail" type="email"
                    pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                    [(ngModel)]="apiPayment.customerEmail" required #customerEmail="ngModel" placeholder="Enter valid email id">
                <!-- <div *ngIf="customerEmail.invalid" style="color: red;">Enter valid email id</div> -->
            </div>
        </div>
        <div class="w3-row w3-section">
            <div class="w3-col w3-third">
                <label class="w3-text-teal"><b>Name</b></label>
            </div>
            <div class="w3-col w3-twothird">
                <input  [disabled]="makePayment" class="w3-input w3-border" [ngClass]="{'w3-border-red': customerName.invalid}" name="customerName" type="text"
                    [(ngModel)]="apiPayment.customerName" required #customerName="ngModel" placeholder="Enter Name">
                <!-- <div *ngIf="customerName.invalid" style="color: red;">Enter Name</div> -->
            </div>
        </div>

        <div class="w3-row w3-section">
            <div class="w3-col w3-third">
                <label class="w3-text-teal"><b>Course Name</b></label>
            </div>
            <div class="w3-col w3-twothird">
                <span>{{ payment.courseName }}</span>
            </div>
            
        </div>

        <div class="w3-row w3-section">
            <div class="w3-col w3-third">
                <label class="w3-text-teal"><b>Section Name</b></label>
            </div>
            <div class="w3-col w3-twothird">
                <span>{{ payment.sectionName }}</span>
            </div>
        </div>

        <div class="w3-row w3-section">
            <div class="w3-col w3-third">
                <label class="w3-text-teal"><b>Amount</b></label>
            </div>
            <div class="w3-col w3-twothird">
                <span>{{ payment.orderAmount | currency:"INR":"symbol" }}</span>

            </div>
        </div>

        <div class="w3-row w3-section">
            <div class="w3-col w3-third">
                <label class="w3-text-teal"><b>Phone</b></label>
            </div>
            <div class="w3-col w3-twothird">
                <input  class="w3-input w3-border" [disabled]="makePayment" [ngClass]="{'w3-border-red': customerPhone.invalid}" name="customerPhone" type="text"
                    [(ngModel)]="apiPayment.customerPhone" required #customerPhone="ngModel" placeholder="Enter Phone">
                <!-- <div *ngIf="customerPhone.invalid" style="color: red;">Enter Phone</div> -->
            </div>
        </div>
        <div class="w3-row w3-section">
            <div class="w3-col w3-third">
                <label class="w3-text-teal"><b>Notes</b></label>
            </div>
            <div class="w3-col w3-twothird">
                <textarea [disabled]="makePayment" class="w3-input w3-border" name="note" maxlength="50" [(ngModel)]="apiPayment.orderNote"
                    #orderNote="ngModel" placeholder="Enter Notes(Max 50 Charters)"></textarea>
            </div>
        </div>
        <div class="w3-row w3-section">
            <div class="w3-col w3-third">
                <label class="w3-text-teal"><b>Order Id</b></label>
            </div>
            <div class="w3-col w3-twothird">
                <span>{{ payment.orderId }}</span>
            </div>
        </div>

        <div class="w3-row w3-section">
            <div>

                <input type="button" [disabled]="makePayment" (click)="SavePayment()" class="w3-btn w3-theme-d2" value="Save">
                <br />
                <br />
            </div>
        </div>
        <!-- <div>
            <pre>{{ frmLogin.value | json }}</pre>
        </div> -->
    </form>
    
    <form *ngIf="apiPayment" ngNoForm id="myForm" method="POST" action="https://test.cashfree.com/billpay/checkout/post/submit">
        <input type="hidden" name="appId" [value]="apiPayment.appId"/>
        <input type="hidden" name="orderId" [value]="apiPayment.orderId"/>
        <input type="hidden" name="orderAmount" [value]="apiPayment.orderAmount"/>
        <input type="hidden" name="orderCurrency" [value]="apiPayment.orderCurrency"/>
        <input type="hidden" name="orderNote" [value]="apiPayment.orderNote"/>        
        <input type="hidden" name="returnUrl" [value]="apiPayment.returnUrl"/>
        <input type="hidden" name="notifyUrl" [value]="apiPayment.notifyUrl"/>
        <input type="hidden" name="signature" [value]="apiPayment.signature"/>
        <input type="hidden" name="customerName" [value]="apiPayment.customerName"/>
        <input type="hidden" name="customerEmail" [value]="apiPayment.customerEmail"/>
        <input type="hidden" name="customerPhone" [value]="apiPayment.customerPhone"/>
        <div class="w3-container">
            <div class="w3-row w3-section">
                <div class="w3-col w3-third">
                    <input type="submit" *ngIf="makePayment" class="w3-btn w3-theme-d2" value="Make Payment">
                </div>              
            </div>
        </div>
        

        
    </form>

</div>
import { Component, OnInit } from '@angular/core';
import { AppNotificationService } from '../services/app-notification.service';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
    notificationmessage: string = '';
    className: string;
    constructor(private notificationService: AppNotificationService) { }

    ngOnInit(): void {
        this.notificationService.messages.subscribe(msg => {
            this.notificationmessage = msg;
            setTimeout(() => {
                this.notificationmessage = '';
            }, 3000);
        });
    }


}

import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/security/services/user.service';
import { AppSettings } from 'src/app/shared/models/appsettings';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { certificate } from '../../models/certificate';
import { UserCertificateService } from '../../services/user-certificate.service';

@Component({
    selector: 'app-user-certificates',
    templateUrl: './user-certificates.component.html',
    
})
export class AppUserCertificatesComponent implements OnInit {
    certificates: certificate[];
    appSettings: AppSettings;
    constructor(private userCertificateService: UserCertificateService, 
        private userService: UserService, 
        private appSettingsService: AppSettingsService) { 

            this.appSettingsService.getSettings().subscribe(data => {
            
                this.appSettings = data;});
    }

    ngOnInit(): void { 
        let userId = this.userService.getLocalStorage("AuthToken").userId;

        this.userCertificateService.getUserCertificates(+userId).subscribe(data => {
            this.certificates = data;
            this.certificates.forEach(cert => 
                cert.document = `${this.appSettings.techCodeLibAPIBaseUrl}${this.appSettings.documents}/${cert.document}`);
        })
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/security/services/user.service';
import { AppSettings } from 'src/app/shared/models/appsettings';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { UserNotificationService } from 'src/app/user-notifications/services/user-notification.service';
import { map, switchMap } from 'rxjs/operators';
import { PaymentService } from '../services/make-payment.service';
import { payment } from '../models/payment';
import { apiPayment } from '../models/apipayment';
import { compileNgModule } from '@angular/compiler';

@Component({
    selector: 'app-make-payment',
    templateUrl: './make-payment.component.html'
})
export class MakePaymentComponent implements OnInit {
    private appSettings: AppSettings;
    private userId: number;
    payment: payment;
    apiPayment: apiPayment
    makePayment: boolean = false;
    paymentSuccess: boolean = false;
    courseId: number;
    referenceId: string;
    constructor(private route: ActivatedRoute,
        private userNotificationService: UserNotificationService,
        private appSettingsService: AppSettingsService,
        private notificationService: AppNotificationService,
        private userService: UserService,
        private router: Router,
        private paymentService: PaymentService) { }

    ngOnInit(): void { 
        this.userId = this.userService.getLocalStorage("AuthToken").userId;
        this.appSettingsService.getSettings().subscribe(data => {
            //console.log(data);
            this.appSettings = data;
        });
        this.route.params.pipe(switchMap(params => {
            var userId = this.userId;
            this.courseId = +params['courseId'];
            var sectionId = +params['sectionId'];
            var msg =  params['msg'];
            this.referenceId = params['refId'];
            
            if(msg == 'SUCCESS')
                this.paymentSuccess = true;

            return this.paymentService.getPaymentToMakeDetail(userId, this.courseId, sectionId, msg);
        })).subscribe(data => {
            console.log(data);
            if(data){
                
                this.payment = data;
                this.apiPayment = {
                    appId: "49817e87d7d24673e659e64d271894",
                    secretKey: "fe953578b86fbc506fd4801556d3e305367c7ce3",
                    orderId: this.payment.orderId,
                    orderAmount: this.payment.orderAmount,
                    orderCurrency: "INR",
                    orderNote: this.payment.note,
                    customerEmail: this.payment.email,
                    customerName: this.payment.name,
                    customerPhone: "",
                    returnUrl: `${this.appSettings.techCodeLibAPIBaseUrl}${this.appSettings.paymentRedirectUrl}`,
                    notifyUrl: `${this.appSettings.techCodeLibAPIBaseUrl}${this.appSettings.paymentNotifyUrl}`,
                    signature: ''
                };
                
            }
            
        });

        
    }

    SavePayment(){
        console.log(this.apiPayment);
        this.paymentService.getSignature(this.apiPayment).subscribe(data => {
            this.apiPayment.signature = data.message;
            console.log(this.apiPayment.signature);
            if(confirm('Are you sure your details below are correct?'))
            {
                this.makePayment = true;
                
            }
            //this.paymentService.makeAPayment(apiPayment).subscribe(x => console.log(x), error => console.log(error));
        });

        
    }
    Submit(){
        this.payment.note = `${this.payment.note}: Course:${this.payment.courseName}, Section:${this.payment.sectionName}`;

        let apiPayment: apiPayment = {
            appId: "49817e87d7d24673e659e64d271894",
            secretKey: "fe953578b86fbc506fd4801556d3e305367c7ce3",
            orderId: this.payment.orderId,
            orderAmount: this.payment.orderAmount,
            orderCurrency: "INR",
            orderNote: this.payment.note,
            customerEmail: this.payment.email,
            customerName: this.payment.name,
            customerPhone: this.payment.phone,
            returnUrl: `http://localhost:4200/Courses/${this.payment.courseId}`,
            notifyUrl: 'http://localhost:4200',
            signature: ''
        };
        //console.log(apiPayment);
        this.paymentService.getSignature(apiPayment).subscribe(data => {
            apiPayment.signature = data.message;
            console.log(apiPayment);
            this.paymentService.makeAPayment(apiPayment).subscribe(x => console.log(x), error => console.log(error));
        });
        //this.paymentService.makeAPayment(apiPayment).subscribe(data => console.log(data));
        
    }
}


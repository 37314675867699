import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../security/services/user.service';
import { userNotification } from '../user-notifications/models/userNotification';
import { UserNotificationService } from '../user-notifications/services/user-notification.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styles: [
  ]
})
export class NavigationComponent implements OnInit {
  loggedIn: boolean;
  userId: number;
  userNotifications: userNotification[];
  hideNav: boolean = true;
  constructor(private userService: UserService, private router: Router, 
    private userNotificationService: UserNotificationService) { }

  ngOnInit(): void {
    this.userNotificationService.getAllUserNotifications().subscribe(data => this.userNotifications = data);
    this.userService.isLoggedInSub.subscribe(val => {

      this.loggedIn = val;          
      console.log(this.loggedIn);
      if(val)
      {
        this.userId = this.userService.getLocalStorage("AuthToken").userId;
        // console.log('a');
        // console.log(this.userService.getLocalStorage("AuthToken"));
      }
        
  });
  console.log(this.userService.getLocalStorage("AuthToken").userId);
  this.userId = this.userService.getLocalStorage("AuthToken").userId;

  // setTimeout(() => {
  //   if(!this.loggedIn){
  //     if(this.router.url != '\Home')
  //     {
  //       console.log('Test');
  //       this.userService.redirectUrl = this.router.url;
  //     }
        
  //     this.router.navigate(['Home']);  
  //   }
          
    
  //   }, 100)
  }

  Signout(){    
    this.loggedIn = false;
    this.userService.Signout();
    this.router.navigateByUrl('Home');
  }

  openNav(navDemo){
    console.log(navDemo);
    navDemo.class = navDemo.class + " w3-show";
  }
}

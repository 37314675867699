import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/shared/models/appsettings';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { blog } from '../models/blog';

@Injectable({
    providedIn: 'root'
})
export class BlogService {
    private appSettings: AppSettings;
    constructor(private http: HttpClient, private appSettingsService: AppSettingsService){
        console.log('BlogService constuctor');
        this.appSettingsService.getSettings().subscribe(data => {          
          this.appSettings = data;});
    }

    getAllBlogs(): Observable<blog[]>
    {
        let allBlogsUrl = this.appSettings.techCodeLibAPIBaseUrl + this.appSettings.blogs;         
        return this.http.get<blog[]>(allBlogsUrl);
    }
}